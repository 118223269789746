import React, { useEffect } from 'react';
import imgbc from '../img//outsourcingcfo/taxhub-accounting-services-at-your.webp';
import imgone from '../img/outsourcingcfo/taxhub-Outstanding-CFO-Function.webp';
import aboutus from '../img/outsourcingcfo/taxhub-Why-require.webp';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const OutsourcingCFO = () => {

    useEffect( () => {

        Aos.init({ duration: 1500 });

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
    } );

  return (
    <>
      <Helmet>
        <title>
          Taxhub | Out Sourcing Firm | CFO | Business Services in UAE
        </title>
        <meta
          name="title"
          content="Taxhub | Out Sourcing Firm | CFO | Business Services in UAE"
        />
        <meta
          name="description"
          content="Outsourcing CFO to us allows businesses service to focus on core operations, benefiting from our expertise for maximizing profitability and growth potential."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/outsourcing-cfo" />
        <meta
          property="og:title"
          content="Taxhub | Out Sourcing Firm | CFO | Business Services in UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/outsourcing-cfo" />
        <meta
          property="og:description"
          content="Outsourcing CFO to us allows businesses service to focus on core operations, benefiting from our expertise for maximizing profitability and growth potential."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-Outstanding-CFO-Function.c99ebdef717122df6ae5.webp"
        />
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-white h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-greenn text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                >
                  Outsourcing CFO Function
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter font-semibold mt-2 mb-6 text-xl sm:text-xl md:text-2xl lg:text-xl xl:text-2xl domine text-justify"
                >
                  We offer CFO services to large and small businesses in Dubai
                  and other UAE emirates.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Top-Notch Tax Support
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  src={imgone}
                  alt="taxhub-business-consultancy"
                  data-aos="zoom-in-up"
                  className="mx-auto block w-3/4 rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 revgradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={aboutus}
                className="mx-auto block"
                alt="taxhub why choose"
                data-aos="flip-right"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-down"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                Why do you require us?
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                How wonderful that your company is expanding! You need the right
                advice from a professional in the area if you want it to
                continue growing. No matter if it's marketing, human resources,
                or money. Finance is the most critical business function that
                uses data and analytical tools to guide company owners in making
                the best choices. Remember to underestimate the time, skill, and
                effort required to manage your investments and make wise
                financial choices.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Additionally, it is ongoing! Better to hire an outside
                consultant if you can't afford a full-time finance planner. If
                you need help with the financial aspects of your company, Taxhub
                can serve as your go-to financial advisor.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 px-2">
        <div className="container mx-auto">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full px-2 sm:px-0 lg:w-[60%] mt-12 lg:mt-0">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Accounting Services at Your Discretion
              </h2>
              <div className="flex flex-col sm:flex-row justify-around md:justify-start mt-4">
                <ul data-aos="fade-up">
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Advice on firm formation and business structure
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    The upkeep of financial bookkeeping
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Saving (Budgeting, Forecasting & Business Plans)
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    The creation of MIS reports and financial statements
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Compiling record schedules for audit and coordinating with
                    inspectors
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Promoting banking and financial ties
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Cash movement and working capital administration
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Services for strategic expense reduction and business
                    process redesign
                  </li>
                </ul>
                <ul data-aos="fade-up">
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Services provided by non-executive directors
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Financial evaluation and filing
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Expert advice for your company planning and forecasting
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Providing accounting staff with training and support{" "}
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Improving business accounting processes
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Communication and negotiation with bankers, lawyers, and
                    auditors
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Filing of Tax and Returns
                  </li>
                  <li className="flex items-center my-2 text-[#0A3242] text-base sm:text-lg md:text-base lg:text-lg domine">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mx-2 text-greenn"
                    />
                    Mergers and Acquisitions
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full px-2 sm:px-0 lg:w-[40%]">
              <img
                src={imgbc}
                alt="taxhub-business-consultancy"
                data-aos="zoom-in-up"
                className="mx-auto block w-3/4 rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Benefits of Chief Financial Officer
            </h2>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Service charge
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Taxation in the United Arab Emirates (UAE) is relatively low
                    compared to many other countries, with no federal income tax
                    or corporate tax on most businesses. However, national and
                    local governments impose certain taxes and fees, such as
                    value-added tax (VAT), excise tax, customs duties, and
                    municipality fees. Here are some benefits these taxes and
                    fees provide to the UAE.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Funding public services
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The taxes collected by the UAE government to support public
                    services such as healthcare, education, infrastructure, and
                    social welfare programs. This guarantees that the UAE
                    continues to develop and prosper while enhancing its
                    citizens' quality of life.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Encouraging economic growth
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Taxes such as VAT and excise tax can generate revenue for
                    the government, which can be used to invest in
                    infrastructure and other projects that promote economic
                    growth. This can help attract foreign investment, create
                    jobs, and boost the economy.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Promoting sustainable development
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    By imposing taxes on certain goods and services, such as
                    tobacco and sugary drinks, the UAE government can promote
                    sustainable development and encourage healthier consumer
                    choices. This may lower healthcare expenses while enhancing
                    public health results.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Supporting national security
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE's low taxation provides benefits such as funding
                    public services, promoting economic growth, encouraging
                    sustainable development, and supporting national security.
                    Taxhub is a financial advisory company offering affordable
                    virtual CFO services in Dubai and other financial services.
                    Their fees are reasonable, and they can assist with critical
                    financial decisions on a tight budget.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    The Stress Busters of Your Existence
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Taxhub offers financial advisory services that can empower
                    and liberate individuals by reducing financial worry and
                    providing mental calmness. Their team of experts can handle
                    CFO-related issues, allowing individuals to focus on other
                    matters. They provide objective advice to help individuals
                    make the best financial decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OutsourcingCFO;