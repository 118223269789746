import React, { useState } from "react";
// import { HiOutlineDocumentAdd, HiOutlineDocumentRemove } from "react-icons/hi";
// import { TbLicense } from "react-icons/tb";
// import { TiDocumentText } from "react-icons/ti";
// import { MdAutorenew } from "react-icons/md";
// import { GrDocumentText } from "react-icons/gr";
import { HiOutlineDocumentAdd, HiOutlineDocumentRemove } from "react-icons/hi";
import { TbLicense } from "react-icons/tb";
import { TiDocumentText } from "react-icons/ti";
import { MdAutorenew } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import Footer from "./Footer";
import axios from "axios";
import { toast } from "react-toastify";

const ConsultationforFREE = () => {

   const [vari, setvari] = useState({
     name: "",
     email: "",
     message: "",
     phone: "",
   });

    const handlechange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setvari((values) => ({ ...values, [name]: value }));
      // if (name === "phone" && /^[0-9]*$/.test(value)) {
      //   // Restrict the value to a maximum of 10 characters
      //   if (value.length <= 10) {
      //     setvari((prevVari) => ({
      //       ...prevVari,
      //       [name]: value,
      //     }));
      //   }
      // }
    };

   const submitfunction = async (e) => {
     e.preventDefault();

     //  var formData = new FormData();
     //  formData.append("jwt", jwt);
     //  formData.append("Name", "");
     //  formData.append("Email", "");
     //  formData.append("Profile_Image", image);

     const response = await axios({
       method: "POST",
       headers: { "Content-Type": "application/json" },
       url: process.env.REACT_APP_API_PATH + "contact",
       data: vari,
     })
       .then(async function (response) {
         const res = await response?.data;
         if (res?.Success) {
           setvari(res?.Success);
           toast.success(res?.Success);
         } else {
           toast.error(res?.Error);
         }
       })
       .catch((err) => {
         throw err;
       });
   };

   const [response, setResponse] = useState([]);
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [mobile, setMobile] = useState("");
   const [position, setPosition] = useState("");
   const [inputs, setInputs] = useState({
     name: "",
     email: "",
   });

   const schedulesubmit = async (e) => {
     e.preventDefault();

     const formData = {
       name: name,
       email: email,
       phone: mobile,
       purpose: showOtherInput ? otherPosition : position,
     };

     try {
       const response = await axios({
         method: "POST",
         headers: { "Content-Type": "application/json" },
         url: process.env.REACT_APP_API_PATH + "callback",
         data: formData,
       });

       const res = response?.data;
       if (res?.Success) {
         toast.success(res?.Success);
       } else {
         toast.error(res?.Error);
       }

       // Reset the form after successful submission
       setPosition("");
       setName("");
       setMobile("");
       setEmail("");
       setOtherPosition("");
       setShowOtherInput(false); // Hide the "Other" input;
     } catch (error) {
       console.error("Error submitting form:", error);
       toast.error("An error occurred while submitting the form.");
     }
   };

    const handlePositionChange = (e) => {
      const selectedPosition = e.target.value;

      if (selectedPosition === "other") {
        setShowOtherInput(true);
      } else {
        setShowOtherInput(false);
        setOtherPosition(""); // Reset the value of the "Other" input when switching to a different option
        setPosition(selectedPosition); // Set the selected position based on the dropdown selection
      }
    };

    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherPosition, setOtherPosition] = useState(""); 

  return (
    <>
      <div className="w-full ">
        <section className="mb-32">
          <div className="relative h-[780px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-mainbg1">
            <div className="flex items-center justify-center mt-12">
              <h1 className="text-4xl sm:text-6xl text-white font-medium">
                PRO Services in Dubai
              </h1>
            </div>
            <div className="flex items-center justify-center mt-5">
              <p className="text-white font-medium sm:text-xl">
                Begin Your Dubai PRO Journey Stress-Free with Our Trusted
                Guidance
              </p>
            </div>
          </div>

          <div className="container px-6 md:px-12 mx-auto">
            <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
              <div className="flex flex-wrap">
                <div className="w-full shrink-0 grow-0 basis-auto ">
                  <div className="flex flex-wrap">
                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <HiOutlineDocumentAdd className="text-2xl text-[white] " />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            Visa Processing
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Streamline your travel with hassle-free visa
                            processing services.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <TbLicense className="text-2xl text-[white]" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            License Application
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Expert assistance in acquiring licenses for seamless
                            business operations.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <TbLicense className="text-2xl text-[white]" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            Translation of Documents
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Bridge language barriers with precise document
                            translation.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <MdAutorenew className="text-2xl text-[white]" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            License Renewal
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Renew licenses effortlessly to maintain your
                            business momentum.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 flex  justify-center">
                      <form onSubmit={schedulesubmit}>
                        <select
                          value={position}
                          required
                          onChange={handlePositionChange} // Use the custom handler to toggle visibility
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        >
                          {/* <option value="">--Position--</option> */}
                          <option value="taxconsultant">Tax Consultant</option>

                          <option value="proservices">PRO Services</option>
                          <option value="visaservices">Visa Services</option>
                          <option value="other">Other</option>
                        </select>
                        {showOtherInput && (
                          <input
                            type="text"
                            placeholder="Other Position"
                            required
                            value={otherPosition}
                            onChange={(e) => setOtherPosition(e.target.value)}
                            className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                          />
                        )}
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          placeholder="Name*"
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        <input
                          type="number"
                          placeholder="Phone*"
                          required
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        {/* {showOtherInput && (
                        <input
                          type="text"
                          placeholder="Other Position"
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      )} */}
                        <input
                          type="submit"
                          className="text-base py-2 ml-auto block px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                        />
                      </form>
                    </div>

                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <TiDocumentText className="text-2xl text-[white]" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            Notarizing Official Documents
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Ensure legality and authenticity through certified
                            document notarization.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                      <div className="flex items-start">
                        <div className="shrink-0">
                          <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                            <HiOutlineDocumentRemove className="text-2xl text-[white]" />
                          </div>
                        </div>
                        <div className="ml-6 grow">
                          <p className="mb-2 font-bold dark:text-[white]">
                            Visa Cancellation
                          </p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                            Professional guidance for stress-free visa
                            cancellation procedures.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ConsultationforFREE;
