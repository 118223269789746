import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DashboardBlogs = () => {
 const [apisubmit, setApisubmit] = useState({ description: "" });
  const [ layout, setLayout ] = useState('layout_one');
  const [ layoutone, setLayoutone ] = useState([]);
  const [update, setupdate] = useState()
   const [editData, setEditData] = useState({
     id: "",
     name: "",
     header: "",
     forURL: "",
     tags: "",
     email: "",
   
   });
   const [showModal, setShowModal] = useState(false);

  useEffect( () => {

    axios
    .get('https://api.taxhub.ae/api/blog')
    .then(response => {
      const data = response.data.data;
      const filter = data.filter(item => item.layout === layout)
      setLayoutone(filter);
    })
    .catch( err => console.log('Error: ', err) )

  }, [layout] );

  const deletefunction = (params) => {
   
      axios
      .delete(`https://api.taxhub.ae/api/blog/${params}`)
      .catch( err => {
        console.log(err.message)
        if( err.message !== '' ) {
          toast.error('Something Went Wrong');
        }
      } )
      .then((response) => {
        if (response.data.message === 'Blog delete successfully') {
          toast.success('Blog Deleted Successfully');
        }
        else {
          toast.error('Something Went Wrong');
        }
      })

  }

 const getfunction = (id) => {
   const formData = new FormData();
   formData.append("id", id);
   axios
     .post(`https://api.taxhub.ae/api/getblog`, formData) // Pass the id parameter in the request body
     .catch((err) => {
       console.log(err.message);
       if (err.message !== "") {
         toast.error("Something Went Wrong");
       }
     })
     .then((response) => {
       console.log(response, "hgh");
       setupdate();
      //  console.log(update?.tags)
       //  if (response.data.message === "Blog delete successfully") {
       //    toast.success("Blog Deleted Successfully");
       //  } else {
       //    toast.error("Something Went Wrong");
       //  }
     });
 };


const openEditModal = (data) => {
  setEditData(data);
  setShowModal(true);
  getfunction(data.id); // Show the ID parameter in the console
};


   const closeEditModal = () => {
     setEditData({
       id: "",
       name: "",
       header: "",
       forURL: "",
       tags: "",
       email: "",
       description: "",
     });
     setShowModal(false);
   };

   const handleEditInputChange = (e) => {
     setEditData({
       ...editData,
       [e.target.name]: e.target.value,
     });
   };

  const handleUpdate = () => {
     const { id, header, tags, forURL, name, email } = editData;
     const payload = {
       id,
       header,
       tags,
       forURL,
       name,
       email,
     description: apisubmit.description, 
     };
    axios
      .post(`https://api.taxhub.ae/api/updateblog`, payload)
      .then((response) => {
        if (response.data.message === "Blog updated successfully") {
          toast.success("Blog Updated Successfully");
          closeEditModal();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong");
      });
  };


  useEffect( () => {
    // handleUpdate();
    getfunction();
   },[])

   const handleEditDescriptionChange = (event, editor) => {
     const data = editor.getData();
     setEditData((prevData) => ({ ...prevData, description: data }));
   };

  return (
    <section className="w-full h-screen">
      <div className="container mx-auto mt-12 px-2 sm:px-4">
        <div className="shadow-md shadow-white p-1 sm:p-4 rounded-xl">
          <h2 className="text-2xl text-hovergreen centurygothic my-6 px-2">
            Add Blogs:
          </h2>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-3">
            <Link
              to="/dashboard/layoutone"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:bg-white hover:text-greenn  domine bg-second  hover:text-second m-0 hover:border-second font_primary bg-greenn"
            >
              <p className="text-xl text-main font_primary text-center">
                Layout 1
              </p>
            </Link>
            <Link
              to="/dashboard/layouttwo"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
            >
              <p className="text-xl text-main font_primary text-center">
                Layout 2
              </p>
            </Link>
            <Link
              to="/dashboard/layoutthree"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
            >
              <p className="text-xl text-main font_primary text-center">
                Layout 3
              </p>
            </Link>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-2xl text-hovergreen centurygothic my-6 px-2">
            Select Layout to delete the Blog:
          </h2>
          <select
            value={layout}
            onChange={(e) => setLayout(e.target.value)}
            className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
          >
            <option value="">--Please choose the layout--</option>
            <option value="layout_one">Layout 1</option>
            <option value="layout_two">Layout 2</option>
            <option value="layout_three">Layout 3</option>
          </select>
          <p className="mt-4 text-hovergreen centurygothic">
            You selected: {layout}
          </p>
          <Table className="mt-10">
            <Thead className="text-third font_primary">
              <Tr>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen rounded-tl-xl">
                  name
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen">
                  header
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen">
                  email
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen">
                  forURL
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen">
                  timezone
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main capitalize border-[white] font-normal text-hovergreen rounded-tr-xl">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody className="border-[1px] border-second">
              {layoutone &&
                layoutone.map((resp) => {
                  const { id, name, forURL, header, created_at, email } = resp;
                  const timezone = new Date(created_at).toLocaleString();
                  return (
                    <Tr key={id}>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {name}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {header}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {email}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {forURL}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {timezone}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white text-center">
                        <span
                          className="hover:cursor-pointer text-[red]"
                          onClick={() => openEditModal(resp)}
                        >
                          Edit
                        </span>
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white text-center">
                        <span
                          className="hover:cursor-pointer text-[red]"
                          onClick={() => deletefunction(id)}
                        >
                          Delete
                        </span>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10">
          <div className="bg-black opacity-75 fixed inset-0"></div>
          <div className="bg-white rounded-lg z-20 p-4 sm:p-8 w-[550px]">
            <h2 className="text-2xl font-bold mb-4">Edit Blog</h2>
            <div className="mb-4">
              <label htmlFor="editname" className="block mb-1">
                Name:
              </label>
              <input
                type="text"
                id="editname"
                name="name"
                value={editData.name}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="editemail" className="block mb-1">
                Email:
              </label>
              <input
                type="text"
                id="editemail"
                name="email"
                value={editData.email}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="editHeader" className="block mb-1">
                Header:
              </label>
              <input
                type="text"
                id="editHeader"
                name="header"
                value={editData.header}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="editforURL" className="block mb-1">
                ForURL:
              </label>
              <input
                type="text"
                id="editforURL"
                name="forURL"
                value={editData.forURL}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="edittag" className="block mb-1">
                Tags:
              </label>
              <input
                type="tags"
                id="edittags"
                name="tags"
                value={editData.tags}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            {/* <div className="flex justify-center items-center flex-col sm:flex-row"> */}
            {/* <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Description: </h2>
              </div> */}
            <div className="mt-4">
              <label htmlFor="edittag" className="block mb-1">
                Description:
              </label>
              <CKEditor
                editor={ClassicEditor}
                value={editData.description}
                data="<p>Welcome to <b>Taxhub</b> !!!<br />
                                <br /></p>"
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setApisubmit({ ...apisubmit, description: data });
                }}
              />
            </div>
            {/* </div> */}
            {/* <div className="mb-4">
              <label htmlFor="edittag" className="block mb-1">
                Description:
              </label>
              <input
                type="description"
                id="editdescription"
                name="description"
                value={editData.description}
                onChange={handleEditInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div> */}
            <div className="flex justify-end">
              <button
                className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 ml-2"
                onClick={closeEditModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default DashboardBlogs;