import React, { useState } from 'react';

function FaqHome(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = props.acco;

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="mx-auto my-8">
        {items.map((item, index) =>  {
            return(
                <div key={index}>
                  <div className="flex justify-between items-center py-4 px-4 bg-[#18904761] cursor-pointer rounded-xl my-2" onClick={() => handleClick(index)} >
                    <h2 className="centurygothic font-bold text-xl text-blackn">{item.title}</h2>
                    <svg className={`w-5 h-5 text-greenn transition-transform ${ activeIndex === index ? 'transform rotate-180' : '' }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
                      <path fillRule="evenodd" d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className={`${ activeIndex === index ? 'block' : 'hidden' } py-2 px-4 domine text-lg`} >
                    {item.content}
                  </div>
                </div>
            )
        })}
    </div>
  );
}

export default FaqHome;