import imgbc from '../img/financeadvisory/taxhub-top-notch-UAE.webp';
import React, { useEffect } from 'react';
import wespecialize from '../img/taxhub-financial-advisory-we-specialize.jpg';
import { faChalkboardTeacher, faInfoCircle, faShieldAlt, faUserTie } from '@fortawesome/free-solid-svg-icons';
import whychoose from '../img/financeadvisory/taxhub-expert-finacial-LIFE.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const FinanceAdvisory = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>Taxhub | Finance Advisory | Tax advisory services in UAE</title>
        <meta
          name="title"
          content="Taxhub | Finance Advisory | Tax advisory services in UAE"
        />
        <meta
          name="description"
          content="Taxhub guide clients on high-return investments, such as the stock market and real estate, and suggest life insurance policies. We also assist with tax preparation. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/finance-advisory" />
        <meta
          property="og:title"
          content="Taxhub | Finance Advisory | Tax advisory services in UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/finance-advisory" />
        <meta
          property="og:description"
          content="Taxhub guide clients on high-return investments, such as the stock market and real estate, and suggest life insurance policies. We also assist with tax preparation. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-top-notch-UAE.005e180a3144fda78a4c.webp"
        />
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-blackn bg-opacity-60 h-full py-16">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  src={imgbc}
                  alt="taxhub-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl text-hovergreen font-bold py-4 centurygothic"
                >
                  Top-notch Financial Advisory Services From Industry Experts in
                  UAE!
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-white bitter mb-8 mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  The financial advisory division of Taxhub Investing comprises
                  internationally qualified advisors with solid industry
                  expertise who attend the top universities around the globe. We
                  can guarantee a distinctive investment strategy for each of
                  our client's thanks to the quality of our staff and our
                  international partnerships. After joining our team, we
                  guarantee you'll always feel confident making investments.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Maximize your refund
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              We specialize in assisting our clients in deciding how and where
              to manage their money
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[70%] mb-4 mx-auto block text-justify">
              Before making any recommendations to help clients reach their
              goals, the Taxhub financial advising division has first-hand
              experience evaluating our client's financial health and
              determining their long-term and short-term goals. Our primary
              responsibility is advising customers on where to invest their
              money, whether in the stock market or other high-return
              investments like real estate. We also recommend life insurance
              policies and provide aid with tax preparation.
            </p>
            <img
              src={wespecialize}
              className="mx-auto block w-3/4"
              alt="we specialize"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
      </section>
      <section className="py-16 bg-financeadvisory bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            Why Choose Us
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[70%] mb-4 mx-auto block text-center">
            Among our clients, we are known for our expertise, dependability,
            and business honesty. We assist clients in making knowledgeable
            market judgements to maintain an edge in the financial markets and
            acquire a competitive advantage.
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-2 lg:grid-cols-4 mt-8">
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faUserTie}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Relationship Manager
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                With us, a committed relationship manager from our trained team
                of specialists will be by your side as you navigate your
                financial markets investment path.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Training for Beginners
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                With the proper training, investing money can be a successful
                activity. Taxhub will provide you with a free training course to
                help you understand the financial markets better.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faShieldAlt}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Guaranteed Financial Safety
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                With strategic planning and the provision of a trustworthy
                platform that is safe, secure, and encrypted, we guarantee our
                customers' complete financial security.
              </p>
            </div>
            <div className="p-4" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Up-to-date Information
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                We give our clients current information about currency and stock
                market movements through periodicals so that they stay informed
                about brand-new developments in the market.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
            The Eight Main Disciplines
          </h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-2 lg:gap-4 xl:gap-6 lg:grid-cols-4 mt-8">
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Transaction services
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                A range of advisory services that deal with problems related to
                mergers, purchases, and sales. Consultants provide various
                services, including due diligence, target screening, valuations,
                and post-merger support.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Corporate finance
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                An essential part of a client's internal financial structure.
                Providing direction on capital management, initial public
                offerings, restructuring, and other significant responsibilities
                under the CFO's responsibility.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Conflict and recovery
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Help clients manage crises, contain damage, and develop
                practical recovery plans. It is crucial to have experience with
                administration, debt management, bankruptcy, and restructuring.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Risk management
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Risk management involves assessing relevant financial risks and
                creating governance structures and initiatives to regulate and
                reduce them. Includes IT risk, compliance, and developing
                precise alarm systems.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Tax advisory
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Help clients comply with tax requirements. Provides advice on
                incorporating tax responsibilities into supply chain
                architecture and tips for corporate tax planning.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Accounting advisory
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Improving accounting processes' efficiency, reducing compliance,
                hastening the flow of financial reporting, and improving
                reporting quality.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Real estate
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Assisting clients with financial interests in real estate
                purchases, sales, and management. The services include asset
                portfolio optimization, location advice, and real estate
                valuations.
              </p>
            </div>
            <div
              className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer"
              data-aos="zoom-in-up"
            >
              <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                Forensics & litigation
              </h2>
              <p className="mt-2 text-black text-base sm:text-base md:text-base lg:text-[14px] xl:text-base domine text-justify">
                Offers various services, including forensic accounting,
                auditing, antitrust advice, and money laundering counsel, to
                help clients win lawsuits and other legal battles.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={whychoose}
                alt="taxhub-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-down"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Expert financial advice may be necessary at various points in
                your life
              </h2>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                We assist you in considering how to employ your money most
                advantageously. A trustworthy choosing financial advisor
                necessitates through research. We put your needs first and plan
                how to get the best ROI quickly. Learn more about us by speaking
                with our advisors.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default FinanceAdvisory;