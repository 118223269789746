import React, { useEffect } from 'react';
import imgone from '../img/professionalfirm/taxhub-professional-firm.webp';
import aboutus from '../img/professionalfirm/taxhub-what-is-professional-firm.webp';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears, faSackDollar, faUsers } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ProfessionalFirm = () => {

    useEffect( () => {

        Aos.init({ duration: 1500 });

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
    } );

  return (
    <>
      <Helmet>
        <title>Taxhub | Professional Firm | Professional Firm In UAE</title>
        <meta
          name="title"
          content="Taxhub | Professional Firm | Professional Firm In UAE"
        />
        <meta
          name="description"
          content="Our guide outlines the steps to establish a UAE professional firm for tax advantages, along with requirements and the pros and cons of this business model. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/professional-firm" />
        <meta
          property="og:title"
          content="Taxhub | Professional Firm | Professional Firm In UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/professional-firm" />
        <meta
          property="og:description"
          content="Our guide outlines the steps to establish a UAE professional firm for tax advantages, along with requirements and the pros and cons of this business model. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-professional-firm.6ed5141d838b437ffb97.webp"
        />
      </Helmet>
      <section className="bg-black">
        <div className="px-2 bg-white h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-greenn text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                >
                  Professional Firm
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  Are you a professional looking to set up a business in the UAE
                  for taxation purposes? Setting up a professional firm could be
                  the answer for you. We will walk you through the steps of
                  forming a professional firm in the UAE for tax reasons in this
                  post, along with the requirements, advantages, and
                  difficulties of this business model.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Your Trusted Tax Partner
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  src={imgone}
                  alt="taxhub-business-consultancy"
                  data-aos="zoom-in-up"
                  className="mx-auto block w-3/4 rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 revgradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={aboutus}
                className="mx-auto block"
                alt="taxhub why choose"
                data-aos="fade-right"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-down"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                What is a Professional Firm?
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                A professional firm is a business established to provide
                professional services, such as legal, accounting, engineering,
                or consultancy. In the UAE, a professional firm is defined as a
                business based on the UAE Civil Code, where the main objective
                is to engage in the practice of a profession. The incorporator
                of a professional firm places a greater emphasis on their
                professional activities than on gaining from the actions of
                those connected to them.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Legal Requirements for Setting up a Professional Firm for Taxation
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[70%] mb-4 mx-auto block text-center">
              To set up a professional firm in the UAE for taxation purposes,
              you need to follow specific legal requirements, including:
            </p>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Obtaining a Professional License
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The first step towards setting up a professional firm is to
                    obtain a professional license from the relevant Emirate
                    Department of Economic Development (DED). The DED requires
                    the following documents: Copy of passport of application,
                    copy of applicant's UAE Recidenec visa, copy of applicants
                    qualifications and experience certificates and no-objection
                    letter from the employer (if employed).
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Choosing a Legal Structure
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    A professional business can be founded as a limited
                    liability company, a partnership, or a sole proprietorship.
                    (LLC). Each legal structure has advantages and
                    disadvantages, and choosing the proper system for your
                    business is essential based on your requirements.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Meeting the Capital Requirements
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Depending on the Emirate and legal structure, different
                    minimum capital requirements for establishing a professional
                    company in the UAE for taxation reasons apply. For example,
                    in Dubai, the minimum capital requirement for a sole
                    proprietorship is AED 50,000, while for an LLC, it is AED
                    300,000.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Registering for Taxation
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Once the professional firm is established, it is essential
                    to register for taxation with the Federal Tax Authority
                    (FTA). The FTA requires all businesses with an annual
                    turnover of AED 375,000 or more to register for VAT.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Maintaining Proper Accounting Records
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Complying with the UAE's taxation regulations is essential
                    to maintain proper accounting records. This includes keeping
                    track of all income, expenses, and taxes paid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-professionalf bg-cover bg-no-repeat bg-center">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            Benefits of Setting up a Professional Firm for Taxation
          </h2>
          <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-3/4 mx-auto block text-center">
            Establishing a professional firm in the UAE for taxation purposes
            offers several benefits, including:
          </p>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 md:grid-cols-3 mt-8">
            <div data-aos="flip-down" className="p-4 relative">
              <FontAwesomeIcon
                icon={faSackDollar}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Tax Savings
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                As a professional firm, you can take advantage of the tax
                benefits available to businesses in the UAE, such as the absence
                of corporate income tax and low VAT rates
              </p>
            </div>
            <div data-aos="flip-down" className="p-4 relative">
              <FontAwesomeIcon
                icon={faGears}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Better Management of Tax Obligations
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                By setting up a professional firm, you can better manage your
                tax obligations and ensure compliance with the regulations in
                the UAE.
              </p>
            </div>
            <div data-aos="flip-down" className="p-4">
              <FontAwesomeIcon
                icon={faUsers}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Access to Tax Professionals
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                As a professional firm, you will have access to tax
                professionals who can assist you in managing your tax
                obligations and ensuring compliance.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greens font-bold py-4 centurygothic w-full md:w-[60%] mx-auto block text-center">
            We would be delighted to assist you if you got in touch with us.{" "}
          </h2>
          <Link
            to="/contact-us"
            data-aos="fade-up"
            className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
          >
            Get Started
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProfessionalFirm;