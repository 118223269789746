import { faBan, faCreditCard, faEye, faFileCircleCheck, faFileContract, faHourglassHalf, faIdCard, faMoneyBillWave, faSmile, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgone from '../img/proservices/taxhub-now-services.webp';
import imgtwo from '../img/proservices/taxhub-pro-services-at.webp';
import accountopening from '../img/proservices/taxhub-bank-account-opening.webp';
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ProServices = () => {

    const [activeIndex, setActiveIndex] = useState();

    const handleClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    useEffect( () => {

        Aos.init({ duration: 1500 });
        
        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
    }, [] );

  return (
    <>
      <Helmet>
        <title>Taxhub | Pro Services | Best Pro Services in Dubai</title>
        <meta
          name="title"
          content="Taxhub | Pro Services | Best Pro Services in Dubai"
        />
        <meta
          name="description"
          content="Our PROs at Taxhub Investment offer specialized public relations expertise to assist clients with governmental and semi-governmental obligations in the UAE."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/pro-services" />
        <meta
          property="og:title"
          content="Taxhub | Pro Services | Best Pro Services in Dubai"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/pro-services" />
        <meta
          property="og:description"
          content="Our PROs at Taxhub Investment offer specialized public relations expertise to assist clients with governmental and semi-governmental obligations in the UAE."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-now-services.3c8c842d8b5beb45e23a.webp"
        />
      </Helmet>
      <section className=" bg-black">
        <div className="px-2 bg-white h-full py-16">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic"
                >
                  Now successfully manage the formalities and procedures with
                  less tension on your shoulders with our expert PRO services!
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  Taxhub Investment's PROs contribute their specialized public
                  relations knowledge to benefit our clients. For all
                  governmental and semi-governmental obligations in the UAE, we
                  explicitly provide help. We represent and connect your company
                  with the appropriate governmental agencies, ministries, and
                  other groups.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  Every account is given a dedicated account manager, whose job
                  is to concentrate on and promptly fulfill the needs of our
                  clients to ensure that we provide the best PRO services in
                  Dubai that they demand.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-greens bitter mt-2 mb-6 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  To free clients from worrying about paperwork, we ensure all
                  inquiries are answered, and all work is finished on time.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-white hover:text-greenn domine hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Efficient Tax Solutions
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <img
                  src={imgone}
                  alt="taxhub-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                PRO Services at a Glance
              </h2>
              <p
                data-aos="fade-right"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                We provide a variety of PRO services, but not just.
              </p>
              <ul data-aos="fade-right">
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCcVisa}
                    className="mx-2 text-hovergreen"
                  />
                  Visa Processing
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faIdCard}
                    className="mx-2 text-hovergreen"
                  />
                  License Application
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faFileContract}
                    className="mx-2 text-hovergreen"
                  />
                  Translation of Documents
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faFileCircleCheck}
                    className="mx-2 text-hovergreen"
                  />
                  Notarizing official documents
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faIdCard}
                    className="mx-2 text-hovergreen"
                  />
                  License Renewal
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className="mx-2 text-hovergreen"
                  />
                  Labor card application and work permits
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faBan}
                    className="mx-2 text-hovergreen"
                  />
                  Visa cancellation
                </li>
                <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base lg:text-lg domine">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="mx-2 text-hovergreen"
                  />
                  NOCs from authorities
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={imgtwo}
                className="mx-auto block"
                data-aos="zoom-in-up"
                alt="taxhub why choose"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              The UAE Ministry of Justice and several other embassies worldwide
              have recognized our translation services
            </h2>
            <Link
              to="/contact-us"
              data-aos="zoom-in-up"
              className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
            >
              Book A Free Consultation
            </Link>
          </div>
        </div>
      </section>
      <section className="py-16 bg-proservicebg bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
            Why Choose Taxhub as your PRO?
          </h2>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-2 lg:grid-cols-4 mt-8">
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faHourglassHalf}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Save Time
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                Considering how challenging it may be to get PRO clearances and
                complete paperwork, working with a company like ours will help
                you save a tonne of time. You can focus more on your main
                business by not waiting in line outside government offices.
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faSmile}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Hassle-Free
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                We simplify a seamless workflow to approve your documents for
                any other reason. We handle all government matters and document
                pickup and distribution after PRO clearance. You are not
                required to participate in any of these tiresome procedures.
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faMoneyBillWave}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Cost Control
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                You will save more money by hiring PRO services rather than
                doing things alone. It will also help you save money on document
                processing and clearing fees. Also, this lessens the need for a
                PRO department inside your company.
              </p>
            </div>
            <div className="p-4 relative" data-aos="flip-down">
              <FontAwesomeIcon
                icon={faEye}
                className="text-white text-3xl text-center mx-auto block"
              />
              <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                Transparency
              </p>
              <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                We are very transparent about our business practices as a
                provider of PRO services. We encourage transparency throughout
                our work by giving all receipts, copies of government fees, and
                other expenses with accompanying bills.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={accountopening}
                alt="taxhub-business-consultancy"
                data-aos="flip-right"
                className="mx-auto block rounded-xl"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Bank Account Opening
              </h2>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The establishment of bank accounts with prestigious
                institutions, including HSBC, ICICI, Hang Seng Bank, Standard
                Chartered Bank, OCBC Wing Hang, and all top banks in the UAE, is
                assisted by Taxhub.
              </p>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Our services aid businesses and people in developing new
                opportunities throughout their ecosystems. Take advantage of
                every chance to transform your company; we are here to assist
                you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 px-2">
        <div className="container mx-auto block">
          <div className="flex justify-center flex-wrap mt-8">
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div
                className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                onClick={() => handleClick(0)}
              >
                <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                  Documents Required To Open Offshore Company Bank Account
                </h2>
                <svg
                  className={`w-5 h-5 transition-transform ${
                    activeIndex === 0 ? "transform rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div
                className={`${
                  activeIndex === 0 ? "block" : "hidden"
                } py-2 px-4 domine text-base text-justify`}
              >
                <ul className="list-disc">
                  <li className="domine text-greens">
                    Incorporation Certificate
                  </li>
                  <li className="domine text-greens">
                    Memorandum of Association
                  </li>
                  <li className="domine text-greens">Share Certificate</li>
                  <li className="domine text-greens">
                    Passport copy of the Shareholder
                  </li>
                  <li className="domine text-greens">
                    Business Profile of Shareholder
                  </li>
                  <li className="domine text-greens">
                    Proof of Business in Home Country
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(1)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Documents Required To Open Freezone Company Bank Account
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 1 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 1 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  <ul className="list-disc">
                    <li className="domine text-greens">
                      Incorporation Certificate
                    </li>
                    <li className="domine text-greens">
                      Memorandum of Association
                    </li>
                    <li className="domine text-greens">Trade License</li>
                    <li className="domine text-greens">Tenancy Contract</li>
                    <li className="domine text-greens">
                      Passport Copy of the Shareholder
                    </li>
                    <li className="domine text-greens">Business Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(2)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Documents Required To Open Personal Bank Account In United
                    Arab Emirates
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 2 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 2 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  <ul className="list-disc">
                    <li className="domine text-greens">Passport Copy</li>
                    <li className="domine text-greens">
                      Emirates ID (for residents)
                    </li>
                    <li className="domine text-greens">
                      Bank reference letter
                    </li>
                    <li className="domine text-greens">Utility Bill</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-center">
            Note: Banks reserve their right to call for any document that seems
            relevant at any point in time
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProServices;