import React, { useState, useEffect } from 'react';
import imgbc from '../img/corporatefinance/taxhub-corporate-funance.webp';
import imgbcn from '../img/corporatefinance/taxhub-an-overview-tax.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const CorporteFinance = () => {

    const [activeIndex, setActiveIndex] = useState();

    const handleClick = (index) => {
        if (activeIndex === index) {
        setActiveIndex(null);
        } else {
        setActiveIndex(index);
        }
    };

    useEffect( () => {

        Aos.init({ duration: 1500 });

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
    }, [] );

  return (
    <>
      <Helmet>
        <title>Taxhub | Corporate Finance | Corporate Tax in UAE</title>
        <meta
          name="title"
          content="Taxhub | Corporate Finance | Corporate Tax in UAE"
        />
        <meta
          name="description"
          content="Corporate finance services offered by Taxhub in assisting businesses with tasks such as mergers & acquisitions, valuations, & financial modeling."
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/corporate-finance" />
        <meta
          property="og:title"
          content="Taxhub | Corporate Finance | Corporate Tax in UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/corporate-finance" />
        <meta
          property="og:description"
          content="Corporate finance services offered by Taxhub in assisting businesses with tasks such as mergers & acquisitions, valuations, & financial modeling."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-corporate-funance.3bb6cf6dd1d4977aa457.webp"
        />
      </Helmet>
      <section className="bg-stars bg-black">
        <div className="px-2 bg-blackn bg-opacity-60 h-full py-20">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  data-aos="fade-up"
                  src={imgbc}
                  alt="taxhub-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-hovergreen text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                >
                  Corporate Finance
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-white bitter mb-8 mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  The <b>federal Corporate Tax</b> (CT) on company net profits
                  will be implemented, according to a January 2022 announcement
                  from the Ministry of Finance. Depending on the business's
                  financial year, either July 1, <b>2023</b>, or January 1,{" "}
                  <b>2024</b>, will be the start date for the tax. All of the
                  emirates will implement CT.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Hassle-Free Tax Preparation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2 px-2">
              <h2
                data-aos="fade-right"
                className="text-greenn text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
              >
                An overview of <br />
                corporate tax
              </h2>
              <p
                data-aos="fade-up"
                className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Following the UAE Federal Decree-Law No. 47 of 2022 on Taxation
                of Corporations and Businesses, businesses will be subject to
                UAE Corporate Tax beginning with their first fiscal year that
                starts on or after June 1, 2023. ("Corporate Tax Law").
              </p>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <img
                src={imgbcn}
                alt="taxhub-business-consultancy"
                data-aos="zoom-in-up"
                className="mx-auto block rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 bg-corporatefinance bg-cover bg-no-repeat bg-center px-2">
        <div className="container mx-auto block">
          <h2
            data-aos="fade-up"
            className="text-hovergreen text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0 w-full md:w-[70%] mx-auto block text-center"
          >
            Corporate tax (CT) - What is it?
          </h2>
          <p
            data-aos="fade-up"
            className="text-white bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-center"
          >
            A type of direct tax known as corporate tax is assessed on the net
            profit or revenue generated by corporations and other legal entities
          </p>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <h2 className="text-greenn text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic text-center">
            Corporate Tax's goals
          </h2>
          <p className="text-black bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-justify">
            Solidify its place as a major international center for trade and
            investment. Accelerate its growth and transformation to accomplish
            its strategic goals and reaffirm its dedication to upholding global
            standards for tax transparency and preventing unfair tax practices.
          </p>
          <div className="flex justify-center flex-wrap">
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div
                className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                onClick={() => handleClick(0)}
              >
                <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                  Scope
                </h2>
                <svg
                  className={`w-5 h-5 transition-transform ${
                    activeIndex === 0 ? "transform rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div
                className={`${
                  activeIndex === 0 ? "block" : "hidden"
                } py-2 px-4 domine text-base text-justify`}
              >
                <ul className="list-disc">
                  <li className="domine text-greens">
                    All companies and people operating legally in the UAE with a
                    trade license.
                  </li>
                  <li className="domine text-greens">
                    Businesses located in free zones (The UAE CT regime will
                    keep honoring the CT incentives presently provided to free
                    zone businesses that adhere to all legal requirements and do
                    not have offices on the UAE's mainland.)
                  </li>
                  <li className="domine text-greens">
                    Only foreign organizations and people who regularly or
                    continuously perform trade or business in the UAE are
                    eligible.
                  </li>
                  <li className="domine text-greens">
                    Companies involved in real estate administration,
                    development, construction, agency, and brokerage.
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(1)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Exemptions from the CT
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 1 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 1 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  <ul className="list-disc">
                    <li className="domine text-greens">
                      A person's salary and other income from employment,
                      whether it comes from the public or private industry
                    </li>
                    <li className="domine text-greens">
                      Interest and other income derived from bank deposits or
                      savings plans by a person
                    </li>
                    <li className="domine text-greens">
                      Dividends, capital gains, interest, royalties, and other
                      investment returns received by an overseas investor
                    </li>
                    <li className="domine text-greens">
                      Individuals investing in real estate in their private
                      capacities
                    </li>
                    <li className="domine text-greens">
                      Dividends, capital gains, and other income people receive
                      from shares or other securities ownership
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-2 w-full md:w-1/3 lg:w-1/3">
              <div>
                <div
                  className="flex justify-between items-center py-4 px-4 bg-lightgreen cursor-pointer my-2 rounded-xl"
                  onClick={() => handleClick(2)}
                >
                  <h2 className="centurygothic font-bold md:text-base lg:text-md xl:text-xl">
                    Moreover, the CT will not apply to
                  </h2>
                  <svg
                    className={`w-5 h-5 transition-transform ${
                      activeIndex === 2 ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    activeIndex === 2 ? "block" : "hidden"
                  } py-2 px-4 domine text-base text-justify`}
                >
                  <ul className="list-disc">
                    <li className="domine text-greens">
                      A person's salary and other income from employment,
                      whether it comes from the public or private industry
                    </li>
                    <li className="domine text-greens">
                      Interest and other income derived from bank deposits or
                      savings plans by a person
                    </li>
                    <li className="domine text-greens">
                      Dividends, capital gains, interest, royalties, and other
                      investment returns received by an overseas investor
                    </li>
                    <li className="domine text-greens">
                      Individuals investing in real estate in their private
                      capacities
                    </li>
                    <li className="domine text-greens">
                      Dividends, capital gains, and other income people receive
                      from shares or other securities ownership
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CorporteFinance;