import imgbc from '../img/gulfcorporatetax/taxhub-impact-of-corporate-tax.webp';
import React, { useEffect } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GulfCorporateTax = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>Taxhub | Gulf Corporate Tax | Financial Service in UAE</title>
        <meta
          name="title"
          content="Taxhub | Gulf Corporate Tax | Financial Service in UAE"
        />
        <meta
          name="description"
          content="By working with Taxhub for your corporate tax needs, businesses can ensure they are meeting their tax obligations while maximizing their financial benefits. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/gulf-corporate-tax" />
        <meta
          property="og:title"
          content="Taxhub | Gulf Corporate Tax | Financial Service in UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta
          property="og:url"
          content="https://taxhub.ae/gulf-corporate-tax"
        />
        <meta
          property="og:description"
          content="By working with Taxhub for your corporate tax needs, businesses can ensure they are meeting their tax obligations while maximizing their financial benefits. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-impact-of-corporate-tax.e621ae94d468e80eb69e.webp"
        />
      </Helmet>
      <section className="h-full md:h-[90vh] bg-gulfcorporate bg-cover bg-no-repeat bg-center">
        <div className="lg:pt-20 h-full px-2">
          <div className="container mx-auto block h-full py-16 md:py-0">
            <div className="flex items-center h-full">
              <div>
                <h2
                  data-aos="fade-down"
                  className="text-hovergreen text-3xl sm:text-5xl text-center md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold md:pl-4 lg:pl-6 mb-6 centurygothic"
                >
                  GCC Gulf Corporate Tax
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  The Gulf Cooperation Council (GCC) countries, including the
                  United Arab Emirates (UAE), have been working on introducing a
                  corporate tax for several years. In May 2021, it was announced
                  that the GCC countries agreed to implement a unified corporate
                  tax framework by 2023.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  The corporate tax will be level on businesses operating in the
                  GCC countries, including the UAE. The tax rate is yet to be
                  determined, but it is expected to be low compared to
                  international standards.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  The introduction of the corporate tax aims to diversify the
                  sources of government revenue and reduce dependence on oil.
                  The revenues generated from the tax will be used to support
                  public services and infrastructure projects in the GCC
                  countries.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-greenn hover:text-white  domine bg-second hover:bg-transparent border-2 border-white domine hover:text-second m-0 bg-white mx-auto block w-max capitalize"
                  data-aos="fade-up"
                >
                  Save time and money on your taxes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={imgbc}
                alt="taxhub-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="flip-right"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl fredoka text-greenn font-bold py-4 centurygothic"
              >
                Impact of Corporate Tax
              </h2>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                Implementing the corporate tax will require businesses operating
                in the GCC countries to comply with new tax laws and
                regulations. To comply with the new tax laws and regulations,
                you must keep accurate books of accounts and financial
                statements.
              </p>
              <p
                data-aos="fade-up"
                className="text-[#0A3242] bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The GCC countries have been improving their tax systems to
                attract foreign investment and enhance competitiveness. The
                introduction of the corporate tax is expected to bring the GCC
                countries in line with international tax standards and improve
                their standing in the global business community.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <h2
            data-aos="fade-up"
            className="text-greenn text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic text-center"
          >
            Conclusion
          </h2>
          <p
            data-aos="fade-up"
            className="text-black bitter mt-2 text-base sm:text-lg md:text-lg lg:text-xl domine w-full md:w-[70%] mb-4 mx-auto block text-justify"
          >
            In conclusion, the GCC Gulf Corporate Tax is set to be implemented
            in the UAE and other GCC countries by 2023. The tax rate is expected
            to be low compared to international standards, and the revenues
            generated from the tax will be used to support public services and
            infrastructure projects. Businesses operating in the GCC countries
            must comply with the new tax laws and regulations, which will
            require proper record-keeping and financial reporting. The
            introduction of corporate tax aims to improve the GCC countries' tax
            systems and enhance their competitiveness in the global business
            community.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default GulfCorporateTax;