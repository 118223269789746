import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const BlogLayoutThree = () => {
  // const [show, setShow] = useState(false);

  const [schedule, setschedule] = useState([]);
  const [response, setResponse] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });
  const [comment, setComment] = useState({
    com: "",
    name: "",
    email: "",
  });

  const location = useLocation();
  const FormID = location.state;

  console.log("location", location.state);
  // let replacedStr = FormID.replace(/\s/g, "-");
  //   console.log(replacedStr, "fdfhsjh");

  useEffect(() => {
    setTimeout(() => {
      // setShow(true)
    }, 3000);
  }, []);

  const fetchfunction = () => {
    axios({
      method: "POST",
      header: "accept: application/json",
      // url : `https://dapib.stashack.cloud:2500/public/view_blog/${FormID}`,
      url: process.env.REACT_APP_API_PATH + "view_blog",
    })
      .then((res) => {
        console.log(res.data.Success);
        setResponse(
          res.data.Success.filter((item) => item.FormID === FormID.FormID)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchfunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timezone = new Date(response?.updated_at).toLocaleString();

  const schedulechange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setschedule((values) => ({ ...values, [name]: value }));
  };

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  //  const schedulesubmit = (e) => {
  //    e.preventDefault();
  //    axios
  //      .post(
  //        "https://dapib.stashack.cloud:3500/public/callback",
  //        schedule,
  //        config
  //      )
  //      .then((response) => {
  //        if (response?.data?.Success== "Message sent successfully!") {
  //          toast.success(response?.data?.Success);
  //          setschedule([]);
  //        } else {
  //          toast.error(response?.data?.Error);
  //        }
  //      })
  //      .catch((error) => {
  //        console.error(error);
  //        toast.error("Something Went Wrong !!!");
  //      });
  //  };

   const schedulesubmit = async (e) => {
     e.preventDefault();

     const formData = {
       name: name,
       email: email,
       phone: mobile,
       purpose: showOtherInput ? otherPosition : position,
     };

     try {
       const response = await axios({
         method: "POST",
         headers: { "Content-Type": "application/json" },
         url: process.env.REACT_APP_API_PATH + "callback",
         data: formData,
       });

       const res = response?.data;
       if (res?.Success) {
         toast.success(res?.Success);
       } else {
         toast.error(res?.Error);
       }

       // Reset the form after successful submission
       setPosition("");
       setName("");
       setMobile("");
       setEmail("");
       setOtherPosition("");
       setShowOtherInput(false); // Hide the "Other" input;
     } catch (error) {
       console.error("Error submitting form:", error);
       toast.error("An error occurred while submitting the form.");
     }
   };

    const handlePositionChange = (e) => {
      const selectedPosition = e.target.value;

      if (selectedPosition === "other") {
        setShowOtherInput(true);
      } else {
        setShowOtherInput(false);
        setOtherPosition(""); // Reset the value of the "Other" input when switching to a different option
        setPosition(selectedPosition); // Set the selected position based on the dropdown selection
      }
    };

    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherPosition, setOtherPosition] = useState(""); 

  // const schedulesubmit = e => {

  //   e.preventDefault();
  //   axios
  //     .post(
  //       "https://dapib.stashack.cloud:3500/public/callback",
  //       schedule,
  //       config
  //     )
  //     .then((response) => {
  //       if (response?.data?.Success ) {
  //         toast.success("Response Submitted !!!");
  //         setschedule([]);
  //       } else {
  //         toast.error("Something Went Wrong !!!");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Something Went Wrong !!!");
  //     });
  // }

  const submitSubcription = async (e) => {
    e.preventDefault();
    const { name, email } = inputs;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "email_subscription",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };

  const submitComment = async (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const { com, name, email } = comment;
    const formData = new FormData();
    formData.append("comment", com);
    formData.append("name", name);
    formData.append("email", email);
    //  formData.append('id', "ERN86544")
    formData.append("id", FormID);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "add_comment",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };

  const [schresponse, setschresponse] = useState([]);

  const listblogs = schresponse.slice(0, 6);

  const schdulefetch = async () => {
    const res = await axios
      .post("https://api.stashack.cloud:5000/public/view_blog", {
        header: "accept: application/json",
      })
      .then((res) => {
        const response = res.data.Success;
        const currentDateTime = new Date();
        let maindata = response
          .filter((resp) => new Date(resp?.Created_On) <= currentDateTime)
          // Filter by publication date
          .slice(0, 6);
        setschresponse(maindata);
        console.log("maindata");
        console.log(maindata);
      })
      .catch((err) => console.log("Error: ", err));
  };

  useEffect(() => {
    schdulefetch();
  }, []);

  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  console.log(response, "helloabc");

  return (
    <>
      <Helmet>
        {/* Set the dynamic title */}
        <title>
          {response?.Heading ? `${response.Heading} | Taxhub` : "Taxhub"}
        </title>

        {/* Set the dynamic description */}
        <meta name="description" content={response?.Subheading} />

        <link rel="canonical" href={canonicalUrl} />

        {/* Set the dynamic Open Graph (OG) tags */}
        <meta property="og:title" content={`${response?.Heading} | Taxhub`} />
        <meta property="og:description" content={response?.Subheading} />
        <meta
          property="og:image"
          content={`https://api.taxhub.ae/banner/${response?.Banner}`}
        />
        <meta property="og:url" content={`https://taxhub.ae/blog/${FormID}`} />

        {/* Add other necessary meta tags as needed */}
      </Helmet>

      {/* <ModalBlog show={ show } onClose={ () => { setShow( false ) } } /> */}
      <section className="pb-16">
        <div className="container mx-auto">
          {response.map((item) => {
            return (
              <div className="flex flex-col lg:flex-row ">
                <div className="w-full lg:w-[70%] px-2 border-r-[1px] border-second">
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      {item.Heading}
                    </h2>
                    <p>
                      by <b className="text-greenn">Taxhub</b> |{" "}
                      {item.Created_On}
                    </p>
                    <p className="font_secondary py-2">{item.Subheading}</p>
                    <img
                      // src={`https://api.earnestroi.com/banner/${response.banner_img}`}
                      src={`https://api.taxhub.ae/banner/${item.Banner}`}
                      className="mx-auto block"
                      alt={item.Tag}
                    />
                  </div>
                  <div
                    className="p-4 font_secondary"
                    dangerouslySetInnerHTML={{ __html: item.Description }}
                  ></div>
                  <div className="py-6 px-4">
                    <p className="font_secondary">
                      <b className="font_primary text-lg text-greenn">
                        Comments:{" "}
                      </b>{" "}
                      {item.Comments?.map((commentItem) => {
                        if (commentItem.APPROVED === "TURE")
                          return (
                            <div className=" py-2">
                              {
                                <div>
                                  {" "}
                                  <span className=" font-semibold">
                                    {commentItem.Name}
                                  </span>{" "}
                                  : <span> {commentItem.Comment}</span>
                                </div>
                              }
                            </div>
                          );
                      })}
                    </p>
                    <p className="font_secondary my-2">
                      <b className="font_primary text-lg text-greenn">
                        Keywords:{" "}
                      </b>{" "}
                      {item.Tag}
                    </p>
                    <b className="font_primary text-xl mt-2   text-greenn">
                      Taxhub
                    </b>
                    <p className="font_secondary text-lg">info@taxhub.ae</p>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-greenn py-4 font-bold centurygothic">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full " onSubmit={submitSubcription}>
                      {/* <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p> */}
                      <div className="flex flex-col md:flex-row w-full gap-4">
                        <input
                          type="text"
                          placeholder="Name*"
                          required
                          value={inputs.name}
                          // onChange={handleChange}
                          onChange={(e) =>
                            setInputs({ ...inputs, name: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          required
                          value={inputs.email}
                          onChange={(e) =>
                            setInputs({ ...inputs, email: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      </div>
                      <input
                        type="submit"
                        className="text-base py-2 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-greenn py-4 font-bold centurygothic">
                      Submit a Comment
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form className="w-full" onSubmit={submitComment}>
                      <div className="flex my-1">
                        <input
                          type="text"
                          placeholder="Name*"
                          required
                          value={comment.name}
                          onChange={(e) =>
                            setComment({ ...comment, name: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          required
                          value={comment.email}
                          onChange={(e) =>
                            setComment({ ...comment, email: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black ml-2"
                        />
                      </div>
                      <textarea
                        placeholder="Comment"
                        value={comment.com}
                        maxLength={250}
                        onChange={(e) =>
                          setComment({ ...comment, com: e.target.value })
                        }
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      <button
                        type="submit"
                        className="text-base py-2 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                      >
                        Submit Comment
                      </button>
                    </form>
                  </div>
                </div>
                <div className="w-full lg:w-[30%] px-2">
                  <div className="px-4 py-8">
                    <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-greenn py-4 font-bold centurygothic">
                      Schedule A Callback
                    </h2>
                    <form onSubmit={schedulesubmit}>
                      <select
                        value={position}
                        required
                        onChange={handlePositionChange} // Use the custom handler to toggle visibility
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      >
                        {/* <option value="">--Position--</option> */}
                        <option value="taxconsultant">Tax Consultant</option>

                        <option value="proservices">PRO Services</option>
                        <option value="visaservices">Visa Services</option>
                        <option value="other">Other</option>
                      </select>
                      {showOtherInput && (
                        <input
                          type="text"
                          required
                          placeholder="Other Position"
                          value={otherPosition}
                          onChange={(e) => setOtherPosition(e.target.value)}
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      )}
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Name*"
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      <input
                        type="number"
                        placeholder="Phone*"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      <input
                        type="email"
                        placeholder="Email*"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      {/* {showOtherInput && (
                        <input
                          type="text"
                          placeholder="Other Position"
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      )} */}
                      <input
                        type="submit"
                        className="text-base py-2 ml-auto block px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-8">
                    <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-greenn py-4 font-bold centurygothic">
                      Recent Posts
                    </h2>

                    <div>
                      {schresponse?.map((item) => (
                        <div className="py-1">
                          <Link
                            key={item.FormID}
                            state={{ FormID: item.FormID }}
                            to={`${window.location.origin}/blog/${item.URL}`}
                            className="hover:text-second hover:cursor-pointer"
                          >
                            <h2>{item.Heading}</h2>
                          </Link>
                        </div>
                      ))}
                    </div>

                    {/* {listblogs &&
                      listblogs.map((resp) => {
                        const { URL, FormID, header, layout } = resp;
                        let path = "";
                        if (layout === 1) {
                          path = "blog";
                        }
                        if (layout === 2) {
                          path = "blog";
                        }
                        if (layout === 3) {
                          path = "blog";
                        }
                        return (
                          <Link to={`/${path}/${FormID}`} key={FormID}>
                            <div className="py-1">
                              <p className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 ">
                                {header}
                              </p>
                            </div>
                          </Link>
                        );
                      })} */}
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-greenn py-4 font-bold centurygothic">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full " onSubmit={submitSubcription}>
                      {/* <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p> */}
                      <div className="flex flex-col md:flex-row w-full gap-4">
                        <input
                          type="text"
                          placeholder="Name*"
                          value={inputs.name}
                          required
                          // onChange={handleChange}
                          onChange={(e) =>
                            setInputs({ ...inputs, name: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          value={inputs.email}
                          required
                          onChange={(e) =>
                            setInputs({ ...inputs, email: e.target.value })
                          }
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      </div>
                      <input
                        type="submit"
                        className="text-base py-2 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                      />
                    </form>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogLayoutThree;
