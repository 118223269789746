import React, { useState, useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMessage, faPhone, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {

    const [open, setOpen] = useState(false);


    const navigate = useNavigate();

    // useEffect(() => {

    //     $(window).on('beforeunload',removeaccess)

    //     // if( !localStorage.getItem('JWT') ) {
    //     //     navigate('/signin');
    //     // }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const removeaccess = () => {
    //     localStorage.removeItem('JWT');
    // }

    useEffect(() => {
      if (!localStorage.getItem("JWT")) {
        navigate("/dashboard");
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoutfunction = (e) => {
        e.preventDefault()
        localStorage.removeItem('JWT');
        navigate('/signin')
        toast('Please Sign In Again !!!')
    }

  return (
    <>
        <div className="flex"> 
            <div className={` ${ open ? "w-12" : "w-[14rem] " } flex flex-col p-3 bg-gray-800 shadow duration-300 check`} id='hamb'>
            <div className="space-y-3">
                <div className="flex items-center justify-between">
                <button className='px-2' onClick={() => setOpen(!open)}>
                    <FontAwesomeIcon className='text-hovergreen' icon={ faBars } />
                </button>
                </div>
                <div className="flex-1">
                <ul className="pt-2 pb-4 space-y-1 text-sm">
                    <li className="rounded-sm">
                    <NavLink to="/dashboard/blogs" activeclassname="active" className="flex items-center p-2 space-x-3 rounded-md" >
                        <FontAwesomeIcon className='text-hovergreen' icon={ faMessage } />
                        <span className={`text-hovergreen label block pl-1 ${ open && "scale-0" }`}>Blogs</span>
                    </NavLink>
                    </li>
                    <li className="rounded-sm">
                    <NavLink to="/dashboard/contact-details" className="flex items-center p-2 space-x-3 rounded-md" >
                        <FontAwesomeIcon className='text-hovergreen' icon={ faPhone } />
                        <span className={`text-hovergreen label block pl-1 ${ open && "scale-0" }`}>Contact</span>
                    </NavLink>
                    </li>
                    
                    <li className="rounded-sm">
                    <NavLink className="flex items-center p-2 space-x-3 rounded-md hover:cursor-pointer" onClick={ logoutfunction }>
                        <FontAwesomeIcon className='text-hovergreen' icon={ faRightFromBracket } />
                        <span className={`text-hovergreen label block pl-1 ${ open && "scale-0" }`}>
                        Logout
                        </span>
                    </NavLink>
                    </li>
                </ul>
                </div>
            </div>
            </div>
            <div className="w-full py-12 px-6 bg-consultantser bg-no-repeat bg-center bg-cover bg-fixed h-full">
            <div className='p-4 rounded-xl w-full bg-col1 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100'>
                <Outlet />
            </div>
            </div>
        </div>
    </>
  )
}

export default Dashboard;