import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LayoutThreeDash = () => {
    const [layout, setLayout] = useState('layout_three');
    const [header, setHeader] = useState('');
    const [url, setUrl] = useState("");
    const [name, setName] = useState('TaxHub');
    const [email, setEmail] = useState('info@taxhub.ae');
    const [subheader, setSubheader] = useState('');
    const [banner, setBanner] = useState(null);
    const [apisubmit, setApisubmit] = useState({ description: '',  });
    const [tags, setTags] = useState('');

    const submitfunctuin = (e) => {
        e.preventDefault();
        console.log(banner);
        const formData = new FormData();
        formData.append('layout', layout);
         formData.append("forURL", url);
        formData.append('header', header);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('subheader', subheader);
        formData.append('banner_img', banner);
        formData.append('description', apisubmit.description);
        formData.append('tags', tags);
        axios.post('https://api.taxhub.ae/api/blog',formData)
        .then( res => {
            if( res.data.message === 'Blog Created successfully' ) {
                toast.success('Blog Created successfully');
            }
        } )
        .catch( err => {
            if( err.message !== '' ) {
                toast.error('Something Went Wrong');
            }
        } )
    }
  return (
    <section className="py-10 mb-20  w-full">
      <div className="py-16">
        <h2 className="centurygothic text-hovergreen text-center text-3xl">
          Layout 3
        </h2>
        <div className="container mx-auto py-8">
          <form className="px-2" onSubmit={submitfunctuin}>
            <div className="justify-center items-center flex-col sm:flex-row hidden">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Layout: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="layout"
                  value={layout}
                  onChange={(e) => setLayout(e.target.value)}
                  readOnly
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="layout_three"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">ForURL: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  name="For URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="For URL"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Header: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  name="header"
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                  type="text"
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Header"
                />
              </div>
            </div>

            <div className="hidden justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Name: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="name"
                  value={name}
                  disabled
                  onChange={(e) => setName(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="hidden justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Email: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="email"
                  value={email}
                  disabled
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Sub-Header: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="subheader"
                  value={subheader}
                  onChange={(e) => setSubheader(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Sub-Header"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Banner: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="file"
                  name="banner_img"
                  onChange={(e) => setBanner(e.target.files[0])}
                  className="text-white border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Sub-Header"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Description: </h2>
              </div>
              <div className="w-full sm:w-3/4 py-2">
                <CKEditor
                  name="description"
                  editor={ClassicEditor}
                  value={apisubmit.description || ""}
                  data="<p>Welcome to <b>Taxhub</b> !!!<br />
                                <br /></p>"
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setApisubmit({ ...apisubmit, description: data });
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="text-hovergreen">Tags: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Tags"
                />
              </div>
            </div>
            <input
              type="submit"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second bg-greenn ml-auto block mt-8"
            />
          </form>
        </div>
      </div>
    </section>
  );
}

export default LayoutThreeDash;