import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactDetailsDash = () => {

  const [response, setResponse] = useState([]);


 

   const fetchfunction = async (e) => {
    //  e.preventDefault();
    //  const { name, email } = response;
     const JWT = localStorage.getItem("JWT");

     const formData = {
       jwt: JWT, // Change "JWT" to "jwt"
     };

     const response = await axios({
       method: "POST",
       headers: { "Content-Type": "application/json" },
       url: process.env.REACT_APP_API_PATH_NAME + "view_contact",
       data: formData,
     })
       .then(async function (response) {
         const res = await response?.data;
         console.log(response,"hghjg")
         if (res?.Success) {
           toast.success(res?.Success);
           setResponse(res?.Success);
           
         } else {
           toast.error(res.Error);
           console.log(res.Error);
         }
         if (res.length > 0) {
           const firstItemId = res[0].id; // Replace 'id' with the actual property name
           deletefunction(firstItemId);
         }
         
       })
       .catch((err) => {
         toast.error(err.response.data.Error);
       });
   };

 

    const deletefunction = async (FormID) => {
      //  e.preventDefault();
      //  const { name, email } = response;
      const JWT = localStorage.getItem("JWT");

      const formData = {
        jwt: JWT,
        id: FormID,
      };

      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_NAME + "delete_contact",
        data: formData,
      })
        .then(async function (response) {
          const res = await response?.data;
          console.log(response, "fhghg");
          if (res?.Success) {
            toast.success(res?.Success);
            // setResponse(res?.Success);
            fetchfunction();
          } else {
            toast.error(res.Error);
            console.log(res.Error);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Error);
        });
    };

  useEffect(() => {
    fetchfunction()
  }, []);

  return (
    <>
      <section className='w-full h-screen'>
        <div className="container mx-auto mt-12 px-2 sm:px-4">
          <div className='container mx-auto'>
            <h2 className='centurygothic text-hovergreen text-center text-6xl'>Contact Details</h2>
          </div>
          <Table className='mt-10'>
            <Thead className='text-third font_primary'>
              <Tr>
                <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal text-hovergreen rounded-tl-xl'>Name</Th>
                <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal text-hovergreen'>Email</Th>
                <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal text-hovergreen'>Phone</Th>
                <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal text-hovergreen'>Comments</Th>
                <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal text-hovergreen rounded-tr-xl'>Action</Th>
              </Tr>
            </Thead>
            <Tbody className='border-[1px] border-second'>
              {

                response && response?.map( (resp) => {

                  const { FormID, Name, Email, Phone, Message } = resp;

                  return (
                    <Tr key={FormID}>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {Name}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {Email}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {Phone}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white">
                        {Message}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-white text-center">
                        <span
                          className="hover:cursor-pointer text-[red]"
                          onClick={() => deletefunction(FormID)}
                        >
                          Delete
                        </span>
                      </Td>
                    </Tr>
                  );

                } )

              }
            </Tbody>
          </Table>
        </div>
      </section>
    </>
  )
}

export default ContactDetailsDash;