import React, { useEffect } from "react";
import whychoose from "../img/aboutus/taxhub-tho-we-are.webp";
import { Link, useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faGraduationCap,
  faHandshakeAlt,
  faIndustry,
  faLightbulb,
  faMedal,
  faShieldAlt,
  faTrophy,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import $ from "jquery";
import { Helmet } from "react-helmet";


//
const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  });

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  });

  const location = useLocation();

  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

 useEffect(() => {
   const metaTags = document.querySelectorAll("meta[data-react-helmet]");
   metaTags.forEach((metaTag) => {
     metaTag.removeAttribute("data-react-helmet");
   });
 }, []);
  
  return (
    <>
      <div>
        <Helmet>
          <title>Taxhub | About Us | Tax Consultancy Services | UAE</title>
          <meta
            name="title"
            content="Taxhub | About Us | Tax Consultancy Services | UAE"
          ></meta>
          <meta
            name="description"
            content="Our team has the necessary skills and resources to offer comprehensive tax-consultant related services to both individuals and businesses."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Taxhub | About Us | Tax Consultancy Services | UAE"
          />
          <meta
            property="og:description"
            content="Our team has the necessary skills and resources to offer comprehensive tax-consultant related services to both individuals and businesses. "
          />

          <meta property="og:url" content="https://taxhub.ae/about-us" />
          <meta
            property="og:image"
            content="https://taxhub.ae/static/media/taxhub-get-to-know-taxhub.9e5f9e9aeef3720e9341.webp"
          />

          <meta property="og:site_name" content="taxhub.ae" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Taxhub | About Us | Tax Consultancy Services | UAE"
          />
          <meta
            name="twitter:description"
            content="Our team has the necessary skills and resources to offer comprehensive tax-consultant related services to both individuals and businesses."
          />
          <meta
            name="twitter:image"
            content="https://taxhub.ae/static/media/taxhub-get-to-know-taxhub.9e5f9e9aeef3720e9341.webp"
          />
          <meta property="twitter:url" content="https://taxhub.ae/about-us" />
          <meta property="twitter:domain" content="taxhub.ae" />
          <meta name="robots" content="index,follow" />

          <link rel="canonical" href={canonicalUrl} />
        </Helmet>

        <section className="pt-[3.6rem] lg:pt-20 h-full md:h-screen bg-aboutus bg-center bg-cover">
          <div className="h-full px-2">
            <div className="container mx-auto block h-full">
              <div className="flex flex-col md:flex-row justify-center pb-16 lg:py-16 md:py-0 items-center h-full">
                <div className="w-full md:w-1/2 invisible"></div>
                <div className="w-full md:w-1/2">
                  <p
                    className="text-white capitalize text-xl mb-4 domine"
                    data-aos="fade-right"
                  >
                    We're tax experts
                  </p>
                  <h2
                    className="text-white text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold border-l-4 border-hovergreen md:pl-4 lg:pl-6 mb-6 centurygothic"
                    data-aos="fade-up"
                  >
                    Get to Know
                    <br />
                    TaxHub
                  </h2>
                  <p
                    className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine"
                    data-aos="fade-up"
                  >
                    Taxhub is a distinguished and authorized public accounting
                    establishment certified in Dubai. Our operations are driven
                    by fundamental principles and a robust ethical framework,
                    which guide our business choices and establish an
                    organizational ambiance that encourages collaboration,
                    cerebral expansion, and exceptional leadership.
                  </p>
                  <div className="flex items-center">
                    <Link
                      to="/contact-us"
                      className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second font_primary bg-greenn"
                      data-aos="fade-up"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-14 px-2">
          <div className="container mx-auto block">
            <p
              data-aos="fade-up"
              className="text-base sm:text-lg md:text-base lg:text-lg text-black mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
            >
              For almost 80 years, we have been rendering top-notch accounting
              and auditing solutions in Dubai to a heterogeneous clientele,
              ranging from sole proprietors to multinational combinations across
              various domains, such as retail and wholesale, information
              technology, manufacturing, healthcare, construction, hospitality,
              entertainment, media, and advertising, and more. The vast
              knowledge we have gathered from our patrons has further
              strengthened.
            </p>
            <p
              data-aos="fade-up"
              className="text-base sm:text-lg md:text-base lg:text-lg text-black mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
            >
              Our position as a premier consultancy firm for business expansion.
              By earning our customers' trust and admiration and delivering
              impressive outcomes, we have established ourselves as one of
              Dubai's leading audit companies, expanding our customer base one
              customer at a time.
            </p>
            <p
              data-aos="fade-up"
              className="text-base sm:text-lg md:text-base lg:text-lg text-black mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
            >
              Despite our evolution, diversification, and the integration of
              modern technology, our accounting enterprise upholds its purpose
              of delivering unparalleled accounting solutions in Dubai,
              characterized by our proficient methodology and extensive
              learning. We look forward to working with you as your partner in
              prosperity as one of the best audit firms in the United Arab
              Emirates, confidently overcoming all challenges and opportunities.
            </p>
          </div>
        </section>
        <section className="py-16 gradienthalf">
          <div className="container mx-auto block">
            <div className="flex flex-col-reverse lg:flex-row items-center">
              <div className="w-full lg:w-1/2 px-2 lg:px-4">
                <h2
                  className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
                  data-aos="fade-right"
                >
                  Who we are
                </h2>
                <p
                  className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                  data-aos="fade-up"
                >
                  Taxhub is one of the top audit firms in Dubai and the UAE. We
                  have been serving clients for over 80 years in tax
                  consultancy. Our team is equipped to provide comprehensive
                  tax-related services to individuals and businesses.
                </p>
                <ul data-aos="fade-up">
                  <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base domine">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="mx-2 text-hovergreen"
                    />
                    Developing a thorough understanding of client expectations
                    through interactive sessions at the proposal stage.
                  </li>
                  <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base domine">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="mx-2 text-hovergreen"
                    />
                    Deploying appropriate resources that focus on realizing
                    client objectives efficiently and on time.
                  </li>
                  <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base domine">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="mx-2 text-hovergreen"
                    />
                    Seeking regular client feedback during fieldwork and
                    complete review to ensure all issues are addressed, and
                    deliverables meet client expectations.
                  </li>
                  <li className="flex items-center my-2 text-white text-base sm:text-lg md:text-base domine">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="mx-2 text-hovergreen"
                    />
                    Ongoing contact with clients posts the submission of
                    deliverables to support them in achieving a positive outcome
                    consistent with their vision.
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-1/2 px-2 md:px-2">
                <img
                  src={whychoose}
                  className="mx-auto block"
                  alt="taxhub why choose"
                  data-aos="zoom-in"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-14 px-2">
          <div className="container mx-auto block">
            <p className="text-base sm:text-lg md:text-base lg:text-lg text-black mb-8 domine text-justify md:text-center w-full md:w-3/4 mx-auto block">
              We always assign a dedicated team of suitably qualified and
              experienced staff members on all our engagements, ensuring
              objective and independent advice while maintaining timely delivery
              and meeting client deadlines and schedules.
            </p>
            <button
              data-aos="fade-up"
              type="submit"
              className="text-base py-4 px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mx-auto block w-max"
            >
              Contact Us
            </button>
          </div>
        </section>
        <section className="py-16 bg-aboutusourvalue bg-cover bg-no-repeat bg-center px-2">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
              OUR VALUES
            </h2>
            <p className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[70%] mb-4 mx-auto block text-center">
              Taxhub is a trusted advisory firm that believes in maintaining a
              long-lasting relationship, consistent approach, and client
              commitment.
            </p>
            <div className="grid grid-cols-1 gap-4 md:gap-8 lg:gap-12 sm:grid-cols-2 lg:grid-cols-4 mt-8">
              <div className="p-4" data-aos="flip-down">
                <FontAwesomeIcon
                  icon={faMedal}
                  className="text-white text-3xl text-center mx-auto block"
                />
                <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                  Leadership
                </p>
                <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                  Our philosophy is centered on offering our clients visionary
                  guidance and empowering them to seize opportunities.
                </p>
              </div>
              <div className="p-4" data-aos="flip-down">
                <FontAwesomeIcon
                  icon={faAward}
                  className="text-white text-3xl text-center mx-auto block"
                />
                <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                  Excellence
                </p>
                <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                  Our ethos revolves around comprehending and delivering what
                  holds the utmost significance to our clients.
                </p>
              </div>
              <div className="p-4" data-aos="flip-down">
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  className="text-white text-3xl text-center mx-auto block"
                />
                <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                  Integrity
                </p>
                <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                  We furnish our clients with integrity, unwavering ethical
                  standards, and firm consistency.
                </p>
              </div>
              <div className="p-4" data-aos="flip-down">
                <FontAwesomeIcon
                  icon={faLightbulb}
                  className="text-white text-3xl text-center mx-auto block"
                />
                <p className="text-hovergreen bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg centurygothic font-semibold text-center">
                  Novelty
                </p>
                <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                  Our professionals possess a strong inclination towards
                  acquiring knowledge and learning new things.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="text-center w-full px-2 py-16 bg-stackingplat bg-no-repeat bg-contain">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center text-blackn py-4 w-full md:w-3/4 mx-auto block font-semibold centurygothic">
              OUR USP'S
            </h2>
            <div className="flex justify-center flex-wrap">
              <div className="p-2 lg:p-4 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="bg-lightgreen hover:bg-hovergreen hover:cursor-pointer h-full rounded-xl"
                  data-aos="zoom-in-up"
                >
                  <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                    <h2 className="w-full text-center centurygothic font-bold text-xl text-greens">
                      <FontAwesomeIcon
                        icon={faTrophy}
                        className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl"
                      />
                    </h2>
                  </div>
                  <div
                    className={`py-2 px-4 domine text-text-lg sm:text-md md:text-lg lg:text-xl text-center`}
                  >
                    We believe in upholding the highest ethical standards and
                    maintaining transparency in our practices.
                  </div>
                </div>
              </div>
              <div className="p-2 lg:p-4 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="bg-lightgreen hover:bg-hovergreen hover:cursor-pointer h-full rounded-xl"
                  data-aos="zoom-in-up"
                >
                  <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                    <h2 className="w-full text-center centurygothic font-bold text-xl text-greens">
                      <FontAwesomeIcon
                        icon={faHandshakeAlt}
                        className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl"
                      />
                    </h2>
                  </div>
                  <div
                    className={`py-2 px-4 domine text-text-lg sm:text-md md:text-lg lg:text-xl text-center`}
                  >
                    We have over 80 years of experience providing consulting
                    services to clients in Dubai, UAE.
                  </div>
                </div>
              </div>
              <div className="p-2 lg:p-4 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="bg-lightgreen hover:bg-hovergreen hover:cursor-pointer h-full rounded-xl"
                  data-aos="zoom-in-up"
                >
                  <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                    <h2 className="w-full text-center centurygothic font-bold text-xl text-greens">
                      <FontAwesomeIcon
                        icon={faUsersCog}
                        className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl"
                      />
                    </h2>
                  </div>
                  <div
                    className={`py-2 px-4 domine text-text-lg sm:text-md md:text-lg lg:text-xl text-center`}
                  >
                    We offer end-to-end management services, ranging from
                    advisory to compliance.
                  </div>
                </div>
              </div>
              <div className="p-2 lg:p-4 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="bg-lightgreen hover:bg-hovergreen hover:cursor-pointer h-full rounded-xl"
                  data-aos="zoom-in-up"
                >
                  <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                    <h2 className="w-full text-center centurygothic font-bold text-xl text-greens">
                      <FontAwesomeIcon
                        icon={faGraduationCap}
                        className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl"
                      />
                    </h2>
                  </div>
                  <div
                    className={`py-2 px-4 domine text-text-lg sm:text-md md:text-lg lg:text-xl text-center`}
                  >
                    Our solutions are backed by research and knowledge.
                  </div>
                </div>
              </div>
              <div className="p-2 lg:p-4 w-full md:w-1/2 lg:w-1/3">
                <div
                  className="bg-lightgreen hover:bg-hovergreen hover:cursor-pointer h-full rounded-xl"
                  data-aos="zoom-in-up"
                >
                  <div className="flex items-center pt-4 px-4  cursor-pointer my-2 rounded-xl">
                    <h2 className="w-full text-center centurygothic font-bold text-xl text-greens">
                      <FontAwesomeIcon
                        icon={faIndustry}
                        className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl"
                      />
                    </h2>
                  </div>
                  <div
                    className={`py-2 px-4 domine text-text-lg sm:text-md md:text-lg lg:text-xl text-center`}
                  >
                    Professionals with industry and consulting backgrounds.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
