import React, { useEffect } from 'react';
import imgbc from '../img/givernmentincentives/taxhub-goverment-subsidies.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faIndustry, faPiggyBank, faStar, faUserTie } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GovernmentIncentives = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>Taxhub | Government Incentives | Dubai Tax incentives</title>
        <meta
          name="title"
          content="Taxhub | Government Incentives | Dubai Tax incentives"
        />
        <meta
          name="description"
          content="Taxhub offer services like government incentives service that help businesses take advantage of various tax incentives and credits offered by the government.  "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/government-incentives" />
        <meta
          property="og:title"
          content="Taxhub | Government Incentives | Dubai Tax incentives"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta
          property="og:url"
          content="https://taxhub.ae/government-incentives"
        />
        <meta
          property="og:description"
          content="Taxhub offer services like government incentives service that help businesses take advantage of various tax incentives and credits offered by the government.  "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-goverment-subsidies.6261908b7293f164ad8b.webp"
        />
      </Helmet>
      <section className="bg-stars bg-black">
        <div className="px-2 bg-blackn bg-opacity-60 h-full py-16">
          <div className="container mx-auto block h-full">
            <div className="flex flex-col md:flex-row justify-center items-center h-full">
              <div className="w-full md:w-1/2">
                <img
                  src={imgbc}
                  alt="taxhub-business-consultancy"
                  className="mx-auto block w-3/4 rounded-xl"
                  data-aos="zoom-in-up"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h2
                  data-aos="fade-right"
                  className="text-hovergreen text-3xl sm:text-5xl md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold mb-6 centurygothic mt-8 md:mt-0"
                >
                  Government Incentives and Subsidies
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-white bitter mb-8 mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
                >
                  The various government incentives and subsidies offered to
                  support your operations in the UAE may pique your interest if
                  you own a company or are an entrepreneur. The United Arab
                  Emirates (UAE) government is committed to fostering a
                  favorable business environment that promotes economic growth
                  and development. One of the ways it achieves this goal is by
                  providing various incentives and subsidies to businesses that
                  meet specific criteria.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 border-2 border-greenn hover:border-greenn font_primary bg-greenn"
                  data-aos="fade-up"
                >
                  Get expert tax advice
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-governmentincentives bg-cover bg-no-repeat bg-center ">
        <div className="py-16">
          <div className="container mx-auto block">
            <h2 className="text-2xl my-6 sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-semibold centurygothic w-full md:w-3/4 mx-auto block  text-center">
              Here are some of the government incentives and subsidies available
              to businesses in the UAE:
            </h2>
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faPiggyBank}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    SME Development Fund
                  </h2>
                  <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    A government program called the SME Development Fund helps
                    SMEs in the United Arab Emirates (UAE). The fund provides
                    financial assistance to SMEs through grants, loans, and
                    equity investment. The fund also offers training and
                    advisory services to help SMEs improve their operations and
                    achieve long-term growth.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Free Zone Incentives
                  </h2>
                  <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE has several free zones that offer various incentives
                    to businesses operating within their jurisdictions. These
                    incentives include tax exemptions, 100% foreign ownership,
                    and simplified business registration procedures. Some of the
                    popular free zones in the UAE include Dubai Multi
                    Commodities Centre (DMCC), Dubai Airport Free Zone (DAFZA),
                    and Jebel Ali Free Zone (JAFZA).
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faIndustry}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Industrial Incentives
                  </h2>
                  <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE government also offers various incentives to
                    businesses that operate in specific industries. For example,
                    companies in the manufacturing sector may be eligible for
                    energy, water, and other utility subsidies. Companies that
                    fund research and development (R&D) or renewable energy
                    initiatives can apply for government grants and loans.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faUserTie}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Employment Incentives
                  </h2>
                  <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE government provides various incentives to businesses
                    that hire Emiratis or invest in their training and
                    development. For example, companies that hire Emiratis may
                    be eligible for subsidies on their salaries or other
                    employment-related expenses. The government also offers
                    training programs and incentives to businesses that invest
                    in developing their Emirati workforce.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="flip-down"
              >
                <div className="h-full p-4">
                  <FontAwesomeIcon
                    icon={faFileExport}
                    className="text-white text-3xl text-center mx-auto block"
                  />
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-hovergreen font-bold text-center centurygothic">
                    Export Incentives
                  </h2>
                  <p className="mt-2 text-white text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE government encourages businesses to export products
                    and services to international markets. The government offers
                    various incentives to companies that ship their goods and
                    services to support this goal. These incentives may include
                    export credit guarantees, finance, and export-related
                    expense subsidies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              The UAE government offers various incentives and subsidies to
              support businesses and individuals. Some of these include
            </h2>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Free zones
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE has designated certain free zones where businesses
                    can operate with 100% foreign ownership, no corporate or
                    income tax, and customs duty exemptions.
                  </p>
                </div>
              </div>
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Startup grants
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The government offers grants to support new businesses and
                    startups, providing funding for research and development,
                    marketing, and other essential expenses.
                  </p>
                </div>
              </div>
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Energy subsidies
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The government provides electricity and water bills to
                    citizens and businesses, reducing the overall cost of
                    utilities.
                  </p>
                </div>
              </div>
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Employment incentives
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The government offers incentives to companies that employ
                    UAE nationals, including training grants, wage subsidies,
                    and other benefits.
                  </p>
                </div>
              </div>
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Infrastructure subsidies
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The government invests heavily in infrastructure
                    development, supporting projects related to transportation,
                    telecommunications, and other essential services
                  </p>
                </div>
              </div>
              <div
                className="p-4 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Customs duty exemptions
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The government offers customs duty exemptions on certain
                    goods, making importing and exporting products more
                    accessible and cost-effective.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default GovernmentIncentives;