import React, { useEffect } from 'react';
import direct from '../img/taxcompliances/direct-tax-cooperation.webp';
import indirect from '../img/taxcompliances/indrect-taxcompliances.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const InderctNDirectTaxes = () => {

    useEffect( () => {

        Aos.init({ duration: 1500 });

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
    } );

  return (
    <>
      <Helmet>
        <title>Taxhub| Compliance Tax | Direct Tax & Indirect Tax In UAE</title>
        <meta
          name="title"
          content="Taxhub| Compliance Tax | Direct Tax & Indirect Tax In UAE"
        />
        <meta
          name="description"
          content="Direct/indirect taxes compliance in UAE is ensured by FTA's guidance and Taxhub helps to get it done. UAE's economic growth is supported by a strong taxation system overseen by FTA. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/compliances-taxes" />
        <meta
          property="og:title"
          content="Taxhub| Compliance Tax | Direct Tax & Indirect Tax In UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta property="og:url" content="https://taxhub.ae/compliances-taxes" />
        <meta
          property="og:description"
          content="Direct/indirect taxes compliance in UAE is ensured by FTA's guidance and Taxhub helps to get it done. UAE's economic growth is supported by a strong taxation system overseen by FTA. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/direct-tax-cooperation.e82df7059d68f961b47b.webp"
        />
      </Helmet>
      <section className="h-full md:h-[90vh] bg-compliancebg bg-cover bg-no-repeat bg-center">
        <div className="lg:pt-20 h-full px-2">
          <div className="container mx-auto block h-full py-16 md:py-0">
            <div className="flex items-center h-full">
              <div>
                <h2
                  data-aos="fade-right"
                  className="text-hovergreen text-3xl sm:text-5xl text-center md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold md:pl-4 lg:pl-6 mb-6 centurygothic w-full sm:w-[85%] md:w-3/4 mx-auto block"
                >
                  Tax Compliances and Advice for Direct and Indirect Taxes
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  Tax Compliances and Guidance for Direct and Indirect Taxes in
                  the UAE Taxes largely influence every nation's economic
                  growth. UAE is not an exception. The Federal Tax Authority
                  oversees the nation's well-established taxation structure
                  (FTA). Compliance with tax rules and regulations is essential
                  for companies conducting business in the UAE. This piece will
                  discuss tax compliance requirements and suggestions for direct
                  and indirect taxes in the United Arab Emirates.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base mt-20 lg:text-lg py-4 px-4 sm:px-10 text-greenn hover:text-white  domine bg-second hover:bg-transparent border-2 border-white domine hover:text-second m-0 bg-white mx-auto block w-max capitalize"
                  data-aos="fade-up"
                >
                  Get peace of mind with our tax services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greenn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Overview of the different types of taxes in the UAE
            </h2>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
              For businesses operating in the UAE, it's essential to comply with
              all applicable tax laws and regulations. It may include
              registering for VAT, filing tax returns, and maintaining accurate
              records. Seeking the advice of a tax professional can help
              businesses stay compliant and minimize their tax liabilities.
            </p>
            <p className="text-black bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full md:w-[85%] lg:w-3/4 mb-4 mx-auto block text-justify">
              Direct and indirect taxes are both a part of the UAE taxation
              structure. Direct taxes are imposed on people and businesses based
              on earnings or revenue. On the other hand, the consumption of
              products and services is subject to indirect taxes. VAT and excise
              duty are the two main categories of indirect taxes in the United
              Arab Emirates.
            </p>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Value Added Tax (VAT)
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    In January 2018, the UAE implemented VAT, an indirect tax
                    that applies to most goods and services at a standard rate
                    of 5%. Some products and services are either VAT exempt or
                    have a zero charge.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Corporate Tax
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    There is no corporate tax in the UAE, which makes it an
                    attractive location for businesses. However, companies may
                    be subject to taxes in other jurisdictions.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Personal Income Tax
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    The UAE does not impose a personal income tax, which makes
                    it a desirable spot for those looking to work or invest in
                    the area.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Customs Duties
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Import duties may apply to goods imported into the UAE. The
                    type of goods and the nation of origin affect the customs
                    duty rate.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Excise Tax
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    An excise tax is a fee on items harmful to the environment
                    or human health, such as tobacco products, energy drinks,
                    and sugary beverages. Depending on the sort of product,
                    there are different excise tax rates.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Growth-oriented
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Advisory services can assist companies in locating growth
                    possibilities and creating plans to take advantage of them.
                    It can help companies to expand their operations and
                    increase their revenue
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 gradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col-reverse lg:flex-row items-center">
            <div className="w-full lg:w-1/2 px-2 lg:px-4">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                Direct Tax Cooperation
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The UAE has implemented several measures to improve
                international and domestic direct tax cooperation. To oversee
                the execution of taxes, such as VAT and excise duty, the UAE has
                set up a Federal Tax Authority (FTA). The FTA is responsible for
                enforcing tax laws and regulations, collecting taxes, and
                conducting audits to ensure compliance.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                The UAE has signed many agreements to promote direct tax
                cooperation with other countries globally. For instance, the UAE
                has signed Double Taxation Avoidance Agreements (DTAAs) with
                numerous nations to avoid double taxation on the same income.
                The UAE has also struck agreements for exchanging information
                with other countries to prevent tax evasion and maintain
                compliance with tax legislation.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={direct}
                className="mx-auto block"
                alt="taxhub why choose"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Types of Direct taxes applicable in the UAE
            </h2>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-center flex-wrap">
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Corporation Revenue Tax (CIT)
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    CIT is irrelevant to most UAE businesses except oil and gas
                    companies and foreign bank branches.
                  </p>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    To meet tax laws and regulations, businesses must keep
                    accurate books of accounts and financial statements.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Withholding Duty
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Dividends, interest, and fees given to non-residents are
                    subject to withholding tax.
                  </p>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Withholding tax returns from businesses must be submitted
                    regularly.
                  </p>
                </div>
              </div>
              <div
                className="p-2 w-full md:w-1/2 lg:w-1/3"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-xl sm:text-2xl md:text-md lg:text-lg xl:text-xl fredoka text-greens font-bold text-start centurygothic">
                    Personal Income Tax (PIT)
                  </h2>
                  <p className="mt-2 text-black text-base sm:text-lg md:text-base lg:text-md domine text-justify">
                    Except for foreigners who work for the government, PIT is
                    irrelevant to most people in the UAE.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 revgradienthalf">
        <div className="container mx-auto block">
          <div className="flex flex-col items-center px-4 md:px-4 lg:px-0 lg:flex-row">
            <div className="w-full lg:w-1/2 px-2 md:px-2">
              <img
                src={indirect}
                className="mx-auto w-3/4 block"
                alt="taxhub why choose"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full lg:w-1/2 py-2 md:py-12 px-4 md:px-4 lg:px-10">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-hovergreen font-bold py-4 centurygothic"
              >
                Indirect Tax Compliances
              </h2>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base xl:text-lg domine text-justify"
              >
                VAT was introduced in the UAE on January 1, 2018, at a standard
                rate of 5%. Registered businesses must charge and remit the VAT
                collected to the (FA) on taxable supplies of goods and services.
                Companies with an annual turnover of AED 187,500 to AED 375,000
                can register voluntarily, while those with an annual turnover of
                AED 375,000 worst register. VAT-registered businesses must
                maintain accurate records or transactions, issue tax invoices,
                and file VAT returns with the FTA regularly.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base xl:text-lg domine text-justify"
              >
                Excise Tax applies to goods considered harmful to human health
                or the environment, such as tobacco, energy, and carbonated
                drinks. The rate of Excise Tax varies depending on the product,
                ranging from 50% for tobacco products to 100% for energy drinks.
                Businesses that import, produce, or stockpile excise goods must
                register with the FTA and comply with Excise Tax regulations,
                including maintaining accurate records and filing tax returns.
              </p>
              <p
                data-aos="fade-up"
                className="text-white bitter mt-2 text-base sm:text-lg md:text-base xl:text-lg domine text-justify"
              >
                Implementing indirect taxes in the UAE has required businesses
                to adapt to new tax compliance obligations. However, the UAE's
                tax regime remains relatively simple compared to other
                jurisdictions, with low tax rates and no other central indirect
                taxes, such as sales tax.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="">
          <div className="container mx-auto block">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-blackn font-bold py-4 centurygothic w-full md:w-[70%] mx-auto block text-center">
              Types of Direct taxes applicable in the UAE
            </h2>
          </div>
          <div className="container mx-auto block">
            <div className="flex justify-around flex-wrap mt-4">
              <div
                className="py-2 px-4 w-full md:w-1/2 lg:w-[40%]"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greens font-bold py-4 centurygothic">
                    Value-Added Tax (VAT){" "}
                  </h2>
                  <ul className="list-unstyled">
                    <li className="domine text-greens">
                      In the UAE, VAT is assessed on providing products and
                      services.
                    </li>
                    <li className="domine text-greens">
                      Businesses with a taxable turnover of AED 375,000 or more
                      must register for VAT.
                    </li>
                    <li className="domine text-greens">
                      Must submit VAT return quarterly.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="py-2 px-4 w-full md:w-1/2 lg:w-[40%]"
                data-aos="zoom-in-up"
              >
                <div className="h-full bg-lightgreen p-4 rounded-xl hover:bg-hovergreen hover:cursor-pointer">
                  <h2 className="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl fredoka text-greens font-bold py-4 centurygothic">
                    Excise Tax
                  </h2>
                  <ul className="list-unstyled">
                    <li className="domine text-greens">
                      Companies that import or manufacture goods subject to
                      excise tax must register.
                    </li>
                    <li className="domine text-greens">
                      Excise tax is levied on consuming particular goods, such
                      as tobacco and sugary beverages.
                    </li>
                    <li className="domine text-greens">
                      Monthly excise tax report filing is required.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InderctNDirectTaxes;