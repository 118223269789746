import React,{ useEffect } from 'react';
import imgbc from '../img/businessconsultancy/taxhub-business-reqtructuring-liabilities.webp';
import Footer from './Footer';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const BusinessConsultancy = () => {

  useEffect( () => {

    Aos.init({ duration: 1500 });

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

  } );

  return (
    <>
      <Helmet>
        <title>
          Taxhub | Business Consultant | Business operations in UAE{" "}
        </title>
        <meta
          name="title"
          content="Taxhub | Business Consultant | Business operations in UAE"
        />
        <meta
          name="description"
          content="Our business consultancy services aim to help businesses achieve their goals & objectives by providing expert guidance & support in various areas of their operations. "
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://taxhub.ae/business-consultancy" />
        <meta
          property="og:title"
          content="Taxhub | Business Consultant | Business operations in UAE"
        />
        <meta property="og:site_name" content="taxhub.ae" />
        <meta
          property="og:url"
          content="https://taxhub.ae/business-consultancy"
        />
        <meta
          property="og:description"
          content="Our business consultancy services aim to help businesses achieve their goals & objectives by providing expert guidance & support in various areas of their operations."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://taxhub.ae/static/media/taxhub-business-reqtructuring-liabilities.4c034c174c4e10241ce2.webp"
        />
      </Helmet>
      <section className="h-full md:h-[90vh] bg-businessconsultancy bg-cover bg-no-repeat bg-center">
        <div className="lg:pt-20 h-full px-2">
          <div className="container mx-auto block h-full py-16 md:py-0">
            <div className="flex items-center h-full">
              <div>
                <h2
                  data-aos="fade-down"
                  className="text-hovergreen text-3xl sm:text-5xl text-center md:text-[2.6rem] lg:text-5xl xl:text-6xl font-semibold md:pl-4 lg:pl-6 mb-6 centurygothic"
                >
                  Business Consultancy
                </h2>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  Companies may experience substantial tax consequences when
                  restructuring their operations in the United Arab Emirates
                  (UAE). Businesses must know these implications and act to
                  abide by UAE tax regulations.
                </p>
                <p
                  data-aos="fade-up"
                  className="text-base sm:text-lg md:text-base lg:text-lg text-white mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
                >
                  Restructuring a business in the UAE can result in tax
                  liabilities, one of the significant tax repercussions. For
                  instance, a business might be required to pay capital gains
                  tax on the sale proceeds if it sells its assets as part of a
                  divestiture. Similarly, if a company merges with another
                  business, profits from the merger may be taxable to the
                  surviving corporation.
                </p>
                <Link
                  to="/contact-us"
                  className="rounded-xl text-base sm:text-lg md:text-base lg:text-lg py-4 px-4 sm:px-10 text-greenn hover:text-white  domine bg-second hover:bg-transparent border-2 border-white domine hover:text-second m-0 bg-white mx-auto block w-max capitalize"
                  data-aos="fade-up"
                >
                  Save time and money on your taxes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 px-2">
        <div className="container mx-auto block">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/2">
              <img
                src={imgbc}
                alt="taxhub-business-consultancy"
                className="mx-auto block rounded-xl"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h2
                data-aos="fade-right"
                className="text-2xl sm:text-2xl lg:text-2xl xl:text-3xl text-greenn font-bold py-4 centurygothic"
              >
                Business restructuring in the UAE can impact a company's tax
                situation and bring about tax liabilities.
              </h2>
              <p
                data-aos="fade-up"
                className="text-blackn bitter mt-2 text-base sm:text-lg md:text-base lg:text-lg domine text-justify"
              >
                For instance, a business might have to reapply for tax
                registration and get a new tax ID number if it combines with
                another company. Similarly, a business may need to update its
                tax registration and adhere to new tax reporting requirements if
                it reorganizes its activities. For that, Taxhub will provide you
                with multiple services like financing (both long- and
                short-term), transaction advisory, contract structuring, VAT
                registration, Business Consultancy, Advisory & Bookkeeping
                Assistance, Tax Compliances, and Advice for Direct and Indirect
                Taxes, Corporate Finance, Outsourcing CFO Function, Transaction
                Service, Government Incentive and Subsidies, GCC Gulf Corporate
                Tax, and compliance.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-14 px-2">
        <div className="container mx-auto block">
          <p
            data-aos="fade-up"
            className="text-base sm:text-lg md:text-base lg:text-lg text-black mb-8 domine text-justify w-full md:w-3/4 mx-auto block"
          >
            Should inform Companies considering reorganizing their operations in
            the UAE of the available tax incentives. The Taxhub provides various
            tax benefits, including exemptions from corporate income tax and
            custom duties for specific kinds of businesses and industries, to
            promote investment and economic development. Companies should
            collaborate with knowledgeable tax advisors and attorneys to
            guarantee compliance with the tax laws in the UAE. These experts can
            offer advice on the tax effect of corporate reorganization and
            assist businesses in navigating the complex tax laws in the UAE. In
            summation, corporate reorganizations in the UAE may have a sizable
            tax impact on businesses. It is critical for companies to be aware
            of these implications and to take the necessary actions to abide by
            UAE tax regulations. Companies should work with knowledgeable tax
            advisers and attorneys to ensure compliance and make the most of any
            potential tax incentives.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BusinessConsultancy;