import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LayoutTwoDash = () => {

    const [layout, setLayout] = useState('layout_two');
    const [banner, setBanner] = useState(null);
    const [header, setHeader] = useState('');
    const [url, setUrl] = useState("");
    const [subheader, setSubheader] = useState('');
    const [name, setName] = useState('TaxHub');
    const [email, setEmail] = useState('info@taxhub.ae');
    const [apisubmit, setApisubmit] = useState({ description: '',  });
    const [gallerytitle, setGallerytitle] = useState('');
    const [gallery_img_one, setGallery_img_one] = useState(null);
    const [gallery_img_two, setGallery_img_two] = useState(null);
    const [gallery_img_three, setGallery_img_three] = useState(null);
    const [imgdesc, setImgdesc] = useState('');
    const [quote, setQuote] = useState('');
    const [quotedesc, setQuotedesc] = useState('');
    const [tags, setTags] = useState('');

    const submitform = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('layout',layout);
        formData.append('banner_img',banner);
         formData.append("forURL", url);
        formData.append('header',header);
        formData.append('subheader',subheader);
        formData.append('name',name);
        formData.append('email',email);
        formData.append('description',apisubmit.description);
        formData.append('gallery_title',gallerytitle);
        formData.append('gallery_img_one',gallery_img_one);
        formData.append('gallery_img_two',gallery_img_two);
        formData.append('gallery_img_three',gallery_img_three);
        formData.append('img_description',imgdesc);
        formData.append('quote',quote);
        formData.append('quote_description',quotedesc);
        formData.append('tags',tags);
        axios.post('https://api.taxhub.ae/api/blog', formData)
        .then( res => {
            if( res.data.message === 'Blog Created successfully' ) {
                toast.success('Blog Created successfully');
            }
        } )
        .catch(err => {
            if( err.message !== '' ) {
                toast.error('Something Went Wrong');
            }
        })
    }

  return (
    <section className="py-8 w-full">
      <h2 className="centurygothic text-hovergreen text-center text-3xl">
        Layout 2
      </h2>
      <div className="container mx-auto py-8">
        <form className="px-2" onSubmit={submitform}>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Layout: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="layout"
                value={layout}
                onChange={(e) => setLayout(e.target.value)}
                readOnly
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="layout"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Banner: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="banner_img"
                onChange={(e) => setBanner(e.target.files[0])}
                className="border-2 text-white border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="centurygothic text-hovergreen">ForURL: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="For URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="text"
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="For URL"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="header"
                type="text"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Sub-Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="subheader"
                type="text"
                value={subheader}
                onChange={(e) => setSubheader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="hidden justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Name: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="name"
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="hidden justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Email: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="email"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Description: </h2>
            </div>
            <div className="w-full sm:w-3/4 py-2">
              <CKEditor
                name="description"
                editor={ClassicEditor}
                value={apisubmit.description || ""}
                data="<p>Welcome to <b>Taxhub</b> !!!<br />
                            <br /></p>"
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setApisubmit({ ...apisubmit, description: data });
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Gallery Titile </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="gallerytitle"
                value={gallerytitle}
                onChange={(e) => setGallerytitle(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Image Gallery Title"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Image one: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgone"
                onChange={(e) => setGallery_img_one(e.target.files[0])}
                className="text-white border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 1"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Image Two: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgtwo"
                onChange={(e) => setGallery_img_two(e.target.files[0])}
                className="text-white border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 2"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Image Three: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgthree"
                onChange={(e) => setGallery_img_three(e.target.files[0])}
                className="text-white border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 3"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Image Description: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="imgdescription"
                value={imgdesc}
                onChange={(e) => setImgdesc(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Image Description"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Quote: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="quote"
                value={quote}
                onChange={(e) => setQuote(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Quote"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Quote Description: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="quotedescription"
                value={quotedesc}
                onChange={(e) => setQuotedesc(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Quote Description"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="text-hovergreen">Tags: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Tags"
              />
            </div>
          </div>
          <input
            type="submit"
            className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:bg-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second bg-greenn ml-auto block mt-8"
          />
        </form>
      </div>
    </section>
  );
}

export default LayoutTwoDash;