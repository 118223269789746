import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footern from "./Footer";
// import ModalBlog from './ModalBlog';
import { toast } from "react-toastify";
import { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons";
import { comment } from "postcss";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const BlogLayoutTwo = () => {
  // const [show, setShow] = useState(false);
  const [response, setResponse] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });
  const [comment, setComment] = useState({
    com: "",
    name: "",
    email: "",
  });

  const location = useLocation();
  const FormID = location.state;

  console.log("location", location.state);
  console.log(response);
  console.log(
    "response.filter",
    response.filter((item) => item.FormID === FormID.FormID)
  );

  useEffect(() => {
    setTimeout(() => {
      // setShow(true)
    }, 3000);
  }, []);

  const fetchfunction = () => {
    axios({
      method: "POST",
      header: "accept: application/json",
      // url : `https://dapib.stashack.cloud:2500/public/view_blog/${FormID}`,
      url: `https://dapib.stashack.cloud:2500/public/view_blog/`,
    })
      .then((res) => {
        console.log(res.data.Success);
        setResponse(
          res.data.Success.filter((item) => item.FormID === FormID.FormID)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchfunction();
  }, []);

  const timezone = new Date(response.updated_at).toLocaleString();

   const handleSubmit = async (e) => {
     e.preventDefault();

     const formData = {
       name: name,
       email: email,
       phone: mobile,
       purpose: showOtherInput ? otherPosition : position,
     };

     try {
       const response = await axios({
         method: "POST",
         headers: { "Content-Type": "application/json" },
         url: process.env.REACT_APP_API_PATH + "callback",
         data: formData,
       });

       const res = response?.data;
       if (res?.Success) {
         toast.success(res?.Success);
       } else {
         toast.error(res?.Error);
       }

       // Reset the form after successful submission
       setPosition("");
       setName("");
       setMobile("");
       setEmail("");
       setOtherPosition("");
       setShowOtherInput(false); // Hide the "Other" input;
     } catch (error) {
       console.error("Error submitting form:", error);
       toast.error("An error occurred while submitting the form.");
     }
   };

    const handlePositionChange = (e) => {
      const selectedPosition = e.target.value;

      if (selectedPosition === "other") {
        setShowOtherInput(true);
      } else {
        setShowOtherInput(false);
        setOtherPosition(""); // Reset the value of the "Other" input when switching to a different option
        setPosition(selectedPosition); // Set the selected position based on the dropdown selection
      }
    };

    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherPosition, setOtherPosition] = useState(""); 

  const submitSubcription = async (e) => {
    e.preventDefault();
    const { name, email } = inputs;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "https://dapib.stashack.cloud:2500/public/email_subscription",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };

  const submitComment = async (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const { com, name, email } = comment;
    const formData = new FormData();
    formData.append("comment", com);
    formData.append("name", name);
    formData.append("email", email);
    //  formData.append('id', "ERN86544")
    formData.append("id", FormID);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "https://dapib.stashack.cloud:2500/public/add_comment",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };

  
  console.log(FormID, "hdgfhdfg");

  return (
    <>
      {/* <ModalBlog show={ show } onClose={ () => { setShow( false ) } } /> */}
      <section className="">
        <div className="container mx-auto">
          {response.map((item) => {
            return (
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-[70%] px-2 border-r-[1px] border-second">
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      {item.Heading}
                    </h2>
                    <p>
                      by <b className="text-third">{response.name}</b> |{" "}
                      {item.Created_On}
                    </p>
                    <p className="font_secondary py-2">{item.Subheading}</p>
                    <img
                      // src={`https://api.earnestroi.com/banner/${response.banner_img}`}
                      src={`https://api.taxhub.ae/banner/${item.Banner}`}
                      className="mx-auto block"
                      alt={item.Tag}
                    />
                  </div>
                  <div
                    className="p-4 font_secondary"
                    dangerouslySetInnerHTML={{ __html: item.Description }}
                  ></div>
                  <div className="py-6 px-4">
                    <p className="font_secondary">
                      <b className="font_primary text-lg text-third">
                        Comments:{" "}
                      </b>{" "}
                      {item.Comments?.map((commentItem) => {
                        if (commentItem.APPROVED === "TURE")
                          return (
                            <div className=" py-2">
                              {
                                <div>
                                  {" "}
                                  <span className=" font-semibold">
                                    {commentItem.Name}
                                  </span>{" "}
                                  : <span> {commentItem.Comment}</span>
                                </div>
                              }
                            </div>
                          );
                      })}
                    </p>
                    <p className="font_secondary">
                      <b className="font_primary text-lg">Keywords: </b>{" "}
                      {item.Tag}
                    </p>
                    <p className="font_primary text-xl text-second">
                      {response.name}
                    </p>
                    <p className="font_secondary text-lg">{response.email}</p>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full " onSubmit={submitSubcription}>
                      {/* <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p> */}
                      <div className="flex flex-col md:flex-row w-full gap-4">
                        <input
                          type="text"
                          placeholder="Name"
                          value={inputs.name}
                          // onChange={handleChange}
                          onChange={(e) =>
                            setInputs({ ...inputs, name: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={inputs.email}
                          onChange={(e) =>
                            setInputs({ ...inputs, email: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                        />
                      </div>
                      <input
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      Submit a Comment
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form className="w-full" onSubmit={submitComment}>
                      <textarea
                        placeholder="Comment"
                        value={comment.com}
                        onChange={(e) =>
                          setComment({ ...comment, com: e.target.value })
                        }
                        className="w-full border-2 border-third py-1 px-2 rounded-lg font_secondary"
                      />
                      <div className="flex my-1">
                        <input
                          type="text"
                          placeholder="Name*"
                          value={comment.name}
                          onChange={(e) =>
                            setComment({ ...comment, name: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          value={comment.email}
                          onChange={(e) =>
                            setComment({ ...comment, email: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full ml-2 font_secondary"
                        />
                      </div>
                      <button
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      >
                        Submit Comment
                      </button>
                    </form>
                  </div>
                </div>
                <div className="w-full lg:w-[30%] px-2">
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl  lg:text-3xl py-4">
                      Schedule A Callback
                    </h2>

                    <form onSubmit={handleSubmit}>
                      <select
                        value={position}
                        required
                        onChange={handlePositionChange} // Use the custom handler to toggle visibility
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      >
                        {/* <option value="">--Position--</option> */}
                        <option value="taxconsultant">Tax Consultant</option>

                        <option value="proservices">PRO Services</option>
                        <option value="visaservices">Visa Services</option>
                        <option value="other">Other</option>
                      </select>
                      {showOtherInput && (
                        <input
                          type="text"
                          required
                          placeholder="Other Position"
                          value={otherPosition}
                          onChange={(e) => setOtherPosition(e.target.value)}
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      )}
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Name*"
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      <input
                        type="number"
                        placeholder="Phone*"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      <input
                        type="email"
                        placeholder="Email*"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                      />
                      {/* {showOtherInput && (
                        <input
                          type="text"
                          placeholder="Other Position"
                          className="my-1 p-2 w-full border-[1px] rounded-md focus-visible:outline-greenn bg-[#1890471f] text-black"
                        />
                      )} */}
                      <input
                        type="submit"
                        className="text-base py-2 ml-auto block px-10 text-greenn bg-transparent domine hover:bg-blackn hover:text-white hover:text-second m-0 hover:border-second font_primary border-2 border-greenn rounded-xl mt-4"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      Recent Posts
                    </h2>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Social Welfare
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Digital Marketing Case Studies For Social Welfare
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Shipping &
                        Logistics Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Digital Marketing Case Studies For Shipping & Logistics
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Health & Fitness
                        Industry
                      </a>
                    </div>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2 text-justify">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full lg:w-3/4">
                      <p className="text-base sm:text-lg font_secondary py-1">
                        Email
                      </p>
                      <input
                        type="email"
                        className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                      />
                      <input
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      />
                    </form>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <Footern />
      {/* <WhatsappChat /> */}
    </>
  );
};

export default BlogLayoutTwo;
