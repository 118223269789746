import React, { useState } from 'react'
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LayoutOneDash = () => {
  const [apisubmit, setApisubmit] = useState({ description: "" });
  const [description, setDescription] = useState(
    "<p>Welcome to <b>Taxhub</b> !!!<br /><br /></p>"
  ); // Initialize with an empty string

  const [img, setImg] = useState(null);
  const [headers, setHeaders] = useState("");
  const [url, setUrl] = useState("");
  const [subheader, setSubheader] = useState("");
  const [formname, setFormname] = useState("TaxHub");
  const [email, setEmail] = useState("info@taxhub.ae");
  const [tags, setTags] = useState("");
  const [layout, setLayout] = useState("layout_one");
  // const [desciption, setDescription] = useState("");
  const [imgPath, setImgPath] = useState(null);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImgPath(file);
  };

  const handleFileChange = (event) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      setImgPath(e.target.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const jwt = localStorage.getItem("JWT");
    const formData = {
      jwt: jwt,
      // banner : imgPath,
      banner: imgPath,
      heading: headers,
      subheading: subheader,
      url: url,
      name: formname,
      email: email,
      description: description,
      tag: tags,
      layout: 1,
      images: [],
      image_description: "",
      gallery_title: "",
      quote_description: "",
      quote: "",
    };

    console.log("sneha ka data", formData);

    axios
      .post("https://api.stashack.cloud:5000/private/add_blog", formData)
      .then((res) => {
        if (res.data.Success) {
          toast.success("Blog Created successfully");
        }
      })
      .catch((err) => {
        if (err.res.data.Error !== "") {
          toast.error("Something Went Wrong");
        }
      });
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  return (
    <section className="pt-8 pb-20 mb-20 h-full ">
      <div className="my-14">
        <h2 className="text-center text-3xl text-hovergreen font-centurygothic">
          Layout 1
        </h2>
        <div className="container mx-auto py-8">
          <form className="px-2" onSubmit={handleSubmit}>
            <div className="justify-center items-center flex-col sm:flex-row hidden">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Layout: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="layout"
                  value={layout}
                  onChange={(e) => setLayout(e.target.value)}
                  readOnly
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="layout"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Header: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  name="header"
                  value={headers}
                  onChange={(e) => setHeaders(e.target.value)}
                  type="text"
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Header"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">ForURL: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  name="For URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="For URL"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Sub-Header: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="subheader"
                  value={subheader}
                  onChange={(e) => setSubheader(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Sub-Header"
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Banner: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="file"
                  name="banner_img"
                  onChange={handleChangeImage}
                  className="border-2 text-white border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Sub-Header"
                />
                {imgPath && (
                  <img
                    src={imgPath}
                    alt="Selected Banner"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                {/* <img src={imgPath} alt="" /> */}
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Description: </h2>
              </div>
              <div className="w-full sm:w-3/4 py-2">
                {/* <CKEditor
                  name="description"
                  editor={ClassicEditor}
                  value={apisubmit.description || ""}
                  data="<p>Welcome to <b>Taxhub</b> !!!<br />
                                <br /></p>"
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setApisubmit({ ...apisubmit, description: data });
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                /> */}
                <CKEditor
                  name="description"
                  editor={ClassicEditor}
                  data={description} // Bind the CKEditor value to the description state
                  onReady={(editor) => {}}
                  onChange={handleDescriptionChange}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>
            <div className="flex justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Tags: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Tags"
                />
              </div>
            </div>
            <div className="hidden justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Name: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="name"
                  disabled
                  value={formname}
                  onChange={(e) => setFormname(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="hidden justify-center items-center flex-col sm:flex-row">
              <div className="w-full  sm:w-1/4">
                <h2 className="centurygothic text-hovergreen">Email: </h2>
              </div>
              <div className="w-full sm:w-3/4">
                <input
                  type="text"
                  name="email"
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  placeholder="Email"
                />
              </div>
            </div>
            <input
              type="submit"
              className="rounded-xl text-base py-4 px-4 sm:px-10 text-white hover:text-greenn  domine bg-second hover:bg-white hover:text-second m-0 hover:border-second bg-greenn ml-auto block mt-8"
            />
          </form>
        </div>
      </div>
    </section>
  );
}

export default LayoutOneDash;