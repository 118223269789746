import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from '../img/taxhub-logo-name.png';
import { Link } from 'react-router-dom';
import { RiTwitterXFill } from "react-icons/ri";


const Footer = () => {
  return (
    <>
      <section className="bg-footer bg-cover bg-no-repeat bg-center">
        <div className="bg-[#184341] pt-16 pb-10 bg-opacity-60">
          <div className="container mx-auto block">
            <div className="flex justify-center items-center flex-wrap">
              <div className="p-2 w-full md:w-1/2 lg:w-1/4 h-max">
                <div className="h-full">
                  <a href="/">
                    <img src={logo} alt="taxhub logo" className="block w-1/2" />
                  </a>
                  <div className="mt-6">
                    <div className="flex justify-start items-start my-2">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="text-[#9bff9b] text-xl mr-4"
                      />
                      <div>
                        <p className="text-white bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                          Looking for collaboration?
                        </p>
                        <a
                          className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full"
                          href="mailto:info@taxhub.ae"
                        >
                          info@taxhub.ae
                        </a>
                      </div>
                    </div>
                    <div className="flex justify-start items-start my-2">
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        className="text-[#9bff9b] text-xl mr-4"
                      />
                      <div>
                        <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                          Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-start items-start my-2">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="text-[#9bff9b] text-xl mr-4"
                      />
                      <div>
                        <p className="text-white bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                          Monday-Friday: 08am-9pm
                        </p>
                        <a
                          className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full"
                          href="tel:+971 52 11 22 777"
                        >
                          (+971) 52 11 22 777
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=100091623931848"
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="bg-greenn text-white p-2 rounded-full mx-2"
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/TaxHub_Official"
                    >
                      <RiTwitterXFill
                        size={31}
                        className="bg-greenn text-white p-2 rounded-full mx-2 -mt-1"
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/taxhub_official/"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="bg-greenn text-white p-2 rounded-full mx-2"
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/taxhubofficial/"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="bg-greenn text-white p-2 rounded-full mx-2"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2 lg:w-1/4 h-max">
                <div className="px-2 lg:px-4">
                  <p className="text-[#9bff9b] bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                    Quick Links
                  </p>
                  <div className="mt-6">
                    <Link to="/audit-and-assurance-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Audit & Assurance
                      </p>
                    </Link>
                    <Link to="/accounting-and-finance-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Accounting & Finance
                      </p>
                    </Link>
                    <Link to="/business-set-up-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Business Setup
                      </p>
                    </Link>
                    <Link to="/corporate-tax-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Corporate Tax
                      </p>
                    </Link>
                    <Link to="/consultant-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Consultant Services
                      </p>
                    </Link>
                    <Link to="/vat-accounting-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        VAT Accounting
                      </p>
                    </Link>
                    <Link to="/compliances-taxes">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Tax Compliances Advice
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2 lg:w-1/4 h-max">
                <div className="px-2 lg:px-4">
                  <p className="text-[#9bff9b] bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                    Useful Links
                  </p>
                  <div className="mt-6">
                    <Link to="/investor-visa">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Investors Visa
                      </p>
                    </Link>
                    <Link to="/golden-visa">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Golden Visa
                      </p>
                    </Link>
                    <Link to="/pro-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        PRO Services
                      </p>
                    </Link>
                    <Link to="/professional-firm">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Professional Firm
                      </p>
                    </Link>
                    <Link to="/outsourcing-cfo">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Outsourcing CFO Function
                      </p>
                    </Link>
                    <Link to="/gulf-corporate-tax">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Gulf Corporate Tax
                      </p>
                    </Link>
                    <Link to="/advisory-bookkeeping">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Advisory & Bookkeeping
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p-2 w-full md:w-1/2 lg:w-1/4 h-max">
                <div className="px-2 lg:px-4">
                  <p className="text-[#9bff9b] bitter text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                    Useful Links
                  </p>
                  <div className="mt-6">
                    <Link to="/corporate-finance">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Corporate Finance
                      </p>
                    </Link>
                    <Link to="/transaction-services">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Transaction Service
                      </p>
                    </Link>
                    <Link to="/government-incentives">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Government Incentives
                      </p>
                    </Link>
                    <Link to="/business-consultancy">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Business Consultancy
                      </p>
                    </Link>
                    <Link to="/finance-advisory">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Finance & Advisory
                      </p>
                    </Link>
                    <Link to="/our-team">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Our Teams
                      </p>
                    </Link>
                    <Link to="/blogs">
                      <p className="text-white hover:text-[#9bff9b] hover:cursor-pointer bitter my-2 text-base sm:text-lg md:text-base lg:text-lg domine w-full">
                        Blogs
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='text-center  text-xl'>
                <p className='text-white bitter'>Powered by <a href="https://advilite.com/" target='_blank' className='underline text-[#9bff9b]'>Advilite</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;