import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
   <React.StrictMode>
    <HelmetProvider>
    <App  />

    <ToastContainer />
    </HelmetProvider>
    </React.StrictMode>
  </>
);
